<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div
          class="statistics-box pa-4"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="font-weight-bold d-flex align-center mt-2">
                Денежный инструмент
                <v-menu offset-x>
                  <template #activator="{ on }">
                    <v-icon
                      tabindex="-1"
                      dense
                      class="mx-1"
                      v-on="on"
                    >
                      mdi-tune
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      :disabled="!selectedDocs.length"
                      @click="deleteSelected"
                    >
                      Удалить выбранные документы
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
            <v-col
              v-if="!money_instruments_json.length"
              cols="12"
              class="text-center"
            >
              <v-btn
                class="document-add elevation-0 px-15"
                color="transparent"
                @click="addPart"
              >
                Добавить <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
            >
              <div
                v-if="money_instruments_json.length"
                class="d-flex align-center"
                style="gap:10px;"
              >
                <div class="t-check">
                  <v-checkbox
                    tabindex="-1"
                    dense
                    hide-details="true"
                    color="#5CB7B1"
                    :value="isAllSelected"
                    @change="checkAll"
                  />
                </div>
                <div
                  class="t-index"
                  style="width:60px;"
                >
                  № п/п
                </div>
                <div
                  class="t-number"
                  style="width:220px;"
                >
                  Наим. денежного инструм.
                </div>
                <div
                  class="t-number"
                  style="width:200px;"
                >
                  Наименование эмитента
                </div>
                <div
                  class="t-number"
                  style="width:160px;"
                >
                  Дата выпуска
                </div>
                <div
                  class="t-number"
                  style="width:140px;"
                >
                  Сумма
                </div>
                <div
                  class="t-descr"
                  style="width: 100px;"
                >
                  Валюта
                </div>
                <div
                  class="t-number"
                  style="width:210px;"
                >
                  ID денежного инструмента
                </div>
                <div
                  class="t-number"
                  style="width:80px;"
                >
                  Кол-во
                </div>
              </div>
              <instrument-item
                v-for="(item, idx) in money_instruments_json"
                :key="idx"
                :item="item"
                :inst-items-length="money_instruments_json.length"
                :index="idx"
                :money-instrument="money_instrument"
                :all-instruments="money_instruments_json"
                :selected-docs="selectedDocs"
                @addPart="addPart"
                @deletePart="deletePart"
                @select="onSelect"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import InstrumentItem from '@/components/passenger/money/instrument-item.vue'

export default {
  components : {
    InstrumentItem
  },
  data: () => ({
    block_id: "passenger-instrument",
    money_instrument: {
      declaration_id: null
    },
    money_instruments_json: [],
    selectedDocs: [],
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      user: "auth/getUser"
    }),
    isAllSelected(){
      return this.selectedDocs.length && this.selectedDocs.length === this.money_instruments_json.length
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    }
  },
  methods: {
    unsetSelected(){
      this.selectedDocs = []
    },
    deleteSelected(){
      this.money_instruments_json = this.money_instruments_json.filter((i,idx) => !this.selectedDocs.includes(idx))
      this.uploadData()
      this.unsetSelected()
    },
    checkAll(){
      if(this.selectedDocs.length === this.money_instruments_json.length){
        this.selectedDocs = []
      }else{
        this.selectedDocs = this.money_instruments_json.map((i, idx) => idx)
      }
    },
    onSelect(idx){
      if(this.selectedDocs.includes(idx)){
        this.selectedDocs = this.selectedDocs.filter(i => i !== idx)
      }else{
        this.selectedDocs.push(idx)
      }
    },
    addPart(){
      this.money_instruments_json.push({
        name: null,
        emitter: null,
        release_date: null,
        sum: null,
        currency_letter: null,
        number: null,
        quantity: null
      })
      this.uploadData()
    },
    deletePart(i){
      this.money_instruments_json.splice(i, 1)
      this.uploadData()
    },
    updatePart(idx, part){
      this.money_instruments_json[idx] = part
      this.uploadData()
    },
    setFields() {
      const money_instrument = this.selected.money_instrument
      Object.keys(this.money_instrument).forEach((key) => {
        if (key in money_instrument) {
          this.money_instrument[key] = money_instrument[key];
        }
      })
      this.money_instruments_json = this.selected.money_instrument.money_instruments_json.length ? [...this.selected.money_instrument.money_instruments_json] : []
    },
    uploadData() {
      const money_instrument = {
        ...this.money_instrument,
        money_instruments_json: [
          ...this.money_instruments_json,
        ],
      }

      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "money_instrument",
        value: money_instrument,
      })
        .then(() => {
          this.$snackbar({text:"Обновлено [Наличные]",color:"green", top:false, right: false});
        })
    },
  }
};
</script>
