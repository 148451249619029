<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        lg="6"
      >
        <div
          class="statistics-box d-md-flex"
        >
          <v-col
            cols="12"
            lg="2"
            class="pr-0"
          >
            <label>Декларация</label>
            <v-text-field
              :value="'ПД'"
              outlined
              hide-details
              dense
              disabled
              background-color="grey lighten-2"
            />
          </v-col>

          <v-col
            cols="12"
            lg="6"
            class="pr-0"
          >
            <label>
              Способ перемещения
              <v-autocomplete
                ref="transit_feature_code"
                v-model="type_declaration.movement_type_code"
                item-text="text"
                item-value="value"
                :items="movement_types"             
                auto-select-first
                outlined
                hide-details="auto"
                dense
                background-color="white"
                class="p-0"
              />
            </label>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <label>
              Корректируемый  документ
              <v-text-field
                ref="specification"
                v-model="type_declaration.correction_reg_num"
                outlined
                :disabled="disabled"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
                dense
                hide-details="auto"
              />
            </label>
          </v-col>
        </div>
      </v-col>

      <v-col
        cols="12"
        lg="6"
      >
        <div class="statistics-box d-md-flex">
          <v-col
            cols="12"
            lg="4"
          >
            <label>
              УИНП
              <v-text-field
                ref="reg_number"
                v-model="registration_details.reg_num_a"
                v-mask="'#####/######/C######'"
                outlined
                dense
                hide-details="auto"
                :disabled="disabled"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
              />
            </label>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <label>
              Номер выпуска
              <v-text-field
                ref="reg_number"
                v-model="registration_details.reg_num_b"
                v-mask="'#####/######/C######'"
                outlined
                dense
                hide-details="auto"
                :disabled="disabled"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
              />
            </label>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <label>
              Дата
            </label>
            <custom-date-picker
              v-model="registration_details.reg_date_a"
              disabled
            />
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
// import blockVisibility from '@/mixins/block-visibility'
// import { eventBus } from "@/bus.js";
// import {
//   type_declaration as onTypeDeclaration,
//   registration_details as onRegistrationDetails,
// } from "@/events/statistics/control";
// import { highlightField } from "@/helpers/control";

export default {
  components: { CustomDatePicker },
  mixins: [blockAutoUpdate],
  data() {
    return {
      date: null, // времменая дата
      block_id: "passenger-main-info",
      disabled: true,
      type_declaration: {
        correction_reg_num: "",
        declaration_id: null,
        movement_type_code: ""
      },
      registration_details: {
        declaration_id: null,
        reg_date_a: "",
        reg_date_b: "",
        reg_num_a: "",
        reg_num_b: ""
      },
      movement_types: [
        {
          text: 'Сопровождаемый багаж',
          value: '1'
        },
        {
          text: 'Несопровождаемый багаж',
          value: '2'
        },
        {
          text: 'Товары, доставляемые перевозчиком',
          value: '3'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
    }),
  },
  watch: {
    selected() {
      this.setFields();
      this.disabled = true
    },
    deep: true
  },
  //   created() {
  //     onTypeDeclaration.subscribe(this.highlightField);
  //     onRegistrationDetails.subscribe(this.highlightField);
  //   },
  //   beforeDestroy() {
  //     onTypeDeclaration.unsubscribe();
  //     onRegistrationDetails.unsubscribe();
  //   },
  methods: {
    // highlightField,
    // clearCountry(v) {
    //   if (v === "ВТ") {
    //     eventBus.$emit("clearCountryTd");
    //   }
    //   if (v === "ИМ") {
    //     eventBus.$emit("clearCountryTd");
    //   }
    // },
    setFields() {
      const { type_declaration } = this.selected || {};
      const { registration_details } = this.selected || {};
      for (let key in type_declaration) {
        if (key in this.type_declaration) {
          this.type_declaration[key] = type_declaration[key];
        }
      }
      for (let key in registration_details) {
        if (key in this.registration_details) {
          this.registration_details[key] = registration_details[key];
        }
      }
      this.setHasChangesFalse();
    },
    async uploadData() {
      const type_declaration = this.convertEmptyStringsToNull(
        this.type_declaration
      );
      const registration_details = this.convertEmptyStringsToNull(this.registration_details);

      await this.$store.dispatch("ptd/uploadBlockData", {
        name: "type_declaration",
        value: type_declaration,
      });
      await this.$store.dispatch("ptd/uploadBlockData", {
        name: "registration_details",
        value: registration_details,
      });
    },
  },
};
</script>
