<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div
          :id="block_id"
          v-click-outside="triggerOnFocus"
          class="statistics-box pa-lg-4"
        >
          <v-row>
            <v-col
              cols="12"
              lg="2"
            >
              <label> Страна отправления </label>
              <v-autocomplete
                v-model="cargo_route_json.dispatch_country_letter"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :items="countries"
                item-text="text"
                item-value="letterCode"
                :filter="filterBySearchField"
              />
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label> Страна назначения </label>
              <v-autocomplete
                v-model="cargo_route_json.destination_country_letter"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :items="countries"
                item-text="text"
                item-value="letterCode"
                :filter="filterBySearchField"
              />
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label>
                Несовершеннолетние дети
                <v-text-field
                  v-model="cargo_route_json.teenager_quantity"
                  v-mask="'##'"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { getCountryNameByCode } from "@/helpers/catalogs"

export default {
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: "passenger-cargo-route",
    cargo_route: {
      declaration_id: null,
    },
    cargo_route_json: {
      destination_country_letter: "",
      destination_country_name: "",
      dispatch_country_letter: "",
      dispatch_country_name: "",
      teenager_quantity: ""
    }
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      countries: "catalogs/getNsiCountries",
      user: "auth/getUser",
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    }
  },
  methods: {
    setFields() {
      const cargo_route = this.selected.cargo_route;
      const cargo_route_json = this.selected.cargo_route.cargo_route_json;

      Object.keys(this.cargo_route).forEach((key) => {
        if (key in cargo_route) {
          this.cargo_route[key] = cargo_route[key];
        }
      });

      Object.keys(this.cargo_route_json).forEach((key) => {
        if (key in cargo_route_json) {
          this.cargo_route_json[key] = cargo_route_json[key];
        }
      });
    },
    uploadData() {
      const cargo_route = this.convertEmptyStringsToNull({
        ...this.cargo_route,
        cargo_route_json: {
          ...this.cargo_route_json,
          destination_country_name: getCountryNameByCode(this.countries, this.cargo_route_json.destination_country_letter) || null,
          dispatch_country_name: getCountryNameByCode(this.countries, this.cargo_route_json.dispatch_country_letter) || null,
        },
      })

      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "cargo_route",
        value: cargo_route,
      });
    },
  }
};
</script>
