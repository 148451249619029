<template>
  <div class="statistics-box pa-4">
    <v-row
      :id="block_id"
      v-click-outside="triggerOnFocus"
    >
      <v-col
        cols="12"
        class="pb-0"
      >
        <div class="font-weight-bold d-flex align-center mt-2">
          Сведения о маршруте и способе перевозки наличных денежных средств и (или) денежных инструментов
          <v-menu offset-x>
            <template #activator="{ on }">
              <v-icon
                tabindex="-1"
                dense
                class="mx-1"
                v-on="on"
              >
                mdi-tune
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                :disabled="!selectedDocs.length"
                @click="deleteSelected"
              >
                Удалить выбранные документы
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="3">
        <label>
          Код вида транспорта
          <v-autocomplete
            v-model="money_transfer_json.vehicle_type"
            auto-select-first
            background-color="white"
            dense
            outlined
            hide-details="true"
            :items="transport_types"
            item-text="text"
            item-value="val"
          />
        </label>
      </v-col>
      <v-col cols="9">
        <label>
          Описание прочего вида транспорта
          <v-text-field
            v-model="money_transfer_json.other_vehicle_description"
            block
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
    </v-row>
    <!-- маршруты массив -->
    <v-row>
      <v-col
        v-if="!money_transfer_routes.length"
        cols="12"
        class="text-center"
      >
        <v-btn
          class="document-add elevation-0 px-15"
          color="transparent"
          @click="addPart"
        >
          Добавить <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <div
          v-if="money_transfer_routes.length"
          class="d-flex align-center"
          style="gap:20px;"
        >
          <div class="t-check">
            <v-checkbox
              tabindex="-1"
              dense
              hide-details="true"
              color="#5CB7B1"
              :value="isAllSelected"
              @change="checkAll"
            />
          </div>
          <div
            class="t-index"
            style="width:60px;"
          >
            № п/п
          </div>
          <div
            class="t-number"
            style="width:120px;"
          >
            Страна
          </div>
          <div
            class="t-descr"
            style="width: 220px;"
          >
            Наименование места
          </div>
          <div
            class="t-descr"
            style="width: 280px;"
          >
            Таможенный орган
          </div>
          <div
            class="t-descr"
            style="width: 160px;"
          >
            Дата
          </div>
        </div>
        <route-item
          v-for="(item, idx) in money_transfer_routes"
          :key="idx"
          :item="item"
          :index="idx"
          :amount="money_transfer_routes.length"
          :base="money_transfer"
          :shell="money_transfer_json"
          :all="money_transfer_routes"
          :customs-points="customsPoints"
          :countries="countries"
          :selected-docs="selectedDocs"
          @addPart="addPart"
          @deletePart="deletePart"
          @select="onSelect"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import RouteItem from "../../../components/passenger/money/route-item.vue"

export default {
  components: { RouteItem },
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: "passenger-money-transfer",
    money_transfer: {
      declaration_id: null,
    },
    money_transfer_json: {
      other_vehicle_description: "",
      vehicle_type: ""
    },
    money_transfer_routes: [],
    selectedDocs: [],
    transport_types: [
      {
        val: '10',
        text: '10-Морской/речной транспорт'
      },
      {
        val: '20',
        text: '20-Железнодорожный транспорт'
      },
      {
        val: '30',
        text: '30-Автодорожный транспорт'
      },
      {
        val: '40',
        text: '40-Воздушный транспорт'
      }
    ],
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      user: "auth/getUser",
      countries: "catalogs/getNsiCountries",
      currencies: "catalogs/getNsiCurrencies",
      customsPoints: "catalogs/getNsiCustomsPoints",
    }),
    isAllSelected(){
      return this.selectedDocs.length && this.selectedDocs.length === this.money_transfer_routes.length
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    }
  },
  methods: {
    unsetSelected(){
      this.selectedDocs = []
    },
    deleteSelected(){
      this.money_transfer_routes = this.money_transfer_routes.filter((i,idx) => !this.selectedDocs.includes(idx))
      this.uploadData()
      this.unsetSelected()
    },
    checkAll(){
      if(this.selectedDocs.length === this.money_transfer_routes.length){
        this.selectedDocs = []
      }else{
        this.selectedDocs = this.money_transfer_routes.map((i, idx) => idx)
      }
    },
    onSelect(idx){
      if(this.selectedDocs.includes(idx)){
        this.selectedDocs = this.selectedDocs.filter(i => i !== idx)
      }else{
        this.selectedDocs.push(idx)
      }
    },
    addPart(){
      this.money_transfer_routes.push({
        country_letter: null,
        destination_place: null,
        destination_customs_code: null,
        date: null
      })
      this.uploadData()
    },
    deletePart(i){
      this.money_transfer_routes.splice(i, 1)
      this.uploadData()
    },
    readyToUpdate(){
      this.hasChanges = true
      this.fields_been_visited = true
    },
    setFields() {
      const money_transfer = this.selected.money_transfer
      const money_transfer_json = this.selected.money_transfer.money_transfer_json

      Object.keys(this.money_transfer).forEach((key) => {
        if (key in money_transfer) {
          this.money_transfer[key] = money_transfer[key]
        }
      })

      Object.keys(this.money_transfer_json).forEach((key) => {
        if (key in money_transfer_json) {
          this.money_transfer_json[key] = money_transfer_json[key]
        }
      })

      this.money_transfer_routes = this.selected.money_transfer.money_transfer_json.money_transfer_routes.length ? [...this.selected.money_transfer.money_transfer_json.money_transfer_routes] : []
    },
    uploadData() {
      const money_transfer = this.convertEmptyStringsToNull({
        ...this.money_transfer,
        money_transfer_json: {
          ...this.money_transfer_json,
          money_transfer_routes: [...this.money_transfer_routes]
        }
      })

      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "money_transfer",
        value: money_transfer
      })
    }
  }
}
</script>
