<template>
  <div

    class="transportation-box rs-goods-item"
  >
    <div
      class="transportation-box__header"
      :class="{
        'transportation-box__header_close': !show,
        'transportation-box__header_open': show
      }"
    >
      <div
        class="d-flex align-center transition-box__params"
        @click.capture="toggle"
      >
        <div>
          <span
            class="font-weight-bold"
          >  {{ index + 1 }}</span>
        </div>

        <!-- <div
          cols="2"
          class="transition-box__param font-weight-bold"
        >
          <span v-if="info.tn_ved">{{ info.tn_ved }} <span class="teal--text">{{ info.add_tn_ved }}</span></span>
        </div> -->
        <!-- <div class="transition-box__param mr-4">
          <p
            class="my-auto"
          >
            {{ title }}
          </p>
        </div> -->
      </div>
      <div class="d-flex">
        <v-btn
          max-height="32px"
          min-width="32px"
          max-width="32px"
          class="float-right ware-item-toggle"
          @focus.prevent
          @click.prevent="toggle"
        >
          <v-icon>
            {{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>

        <v-menu
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-btn
              tabindex="-1"
              max-height="32px"
              min-width="32px"
              max-width="32px"
              color="#5CB7B1"
              v-bind="attrs"
              class="ml-2 wares-item-menu"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              class="wares-item-menu-delete"
              @click="deleteItem"
            >
              Удалить
            </v-list-item>
            <v-list-item
              class="wares-item-menu-copy"
            >
              Копировать
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div
      :id="block_id"
      v-click-outside="triggerOnFocus"
      class="editable-content"
    >
      <v-expand-transition>
        <div v-show="show">
          <div
            class="statistics-box pa-4"
          >
            <v-row>
              <v-col cols="6">
                <label for="">
                  Вид транспортного средства
                  <v-autocomplete
                    v-model="info.vehicle_type_code"
                    auto-select-first
                    background-color="white"
                    dense
                    outlined
                    hide-details="true"
                    :items="transport_types"
                    item-text="text"
                    item-value="val"
                  />
                </label>
              </v-col>
              <v-col
                cols="6"
                class="pt-10"
              >
                <v-checkbox
                  v-model="info.trailer"
                  color="#5cb7b1"
                  hide-details="true"
                  label="Прицеп"
                  :disabled="info.vehicle_type_code !== '30'"
                  @change="readyToUpdate"
                />
              </v-col>
              <v-col
                cols="2"
              >
                <label>
                  Рег. номер
                  <v-text-field
                    v-model="info.number"
                    v-mask="'XXXXXXXXXX'"
                    block
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    @input="(v) => (info.number = v.toLocaleUpperCase())"
                  />
                </label>
              </v-col>
              <v-col
                cols="2"
              >
                <label>
                  Страна рег.
                  <v-autocomplete
                    v-model="info.country_letter"
                    auto-select-first
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    :items="countries"
                    item-text="text"
                    item-value="letterCode"
                    :filter="filterBySearchField"
                  />
                </label>
              </v-col>
              <v-col cols="8">
                <!-- <label>
                  Тип транспортного средства
                  <v-autocomplete
                    v-model="info.vehicle_type_code"
                    auto-select-first
                    background-color="white"
                    dense
                    outlined
                    hide-details="true"
                    item-value="code"
                    item-text="search"
                    :items="vehicleTypes"
                    :filter="filterBySearchField"
                  />
                </label> -->
              </v-col>
              <v-col cols="3">
                <label>
                  Марка транспортного средства
                  <!-- <v-autocomplete
                    v-model="info.transport_mark_code"
                    auto-select-first
                    background-color="white"
                    dense
                    outlined
                    hide-details="true"
                    item-value="code"
                    item-text="search"
                    :items="vehicleTypes"
                    :filter="filterBySearchField"
                  /> -->
                  <v-autocomplete
                    v-model="info.transport_mark_name"
                    auto-select-first
                    :items="nsiTransportMarks"
                    item-text="text"
                    item-value="name"
                    background-color="white"
                    :filter="filterBySearchField"
                    outlined
                    dense
                    hide-details="auto"
                  />
                </label>
              </v-col>
              <v-col cols="3">
                <label>
                  Модель транспортного средства
                  <v-text-field
                    v-model="info.model_name"
                    block
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                  />
                </label>
              </v-col>
              <v-col cols="6" />
              <v-col cols="3">
                <label>VIN/шасси/кузов</label>
                <v-radio-group
                  v-model="info.vin_type"
                  hide-details="true"
                  class="mt-2"
                  @change="readyToUpdate"
                >
                  <v-radio
                    class="mb-0"
                    label="Vin"
                    :value="1"
                    color="#5CB7B1"
                  />
                  <v-radio
                    class="mb-0"
                    label="Шасси"
                    :value="2"
                    color="#5CB7B1"
                  />
                  <v-radio
                    class="mb-0"
                    label="Кузов"
                    :value="3"
                    color="#5CB7B1"
                  />
                </v-radio-group>
              </v-col>
              <v-col cols="2">
                <label>
                  Номер VIN/шасси/кузова
                  <v-text-field
                    v-model="info.vin"
                    v-mask="customVinMask"
                    block
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                  />
                </label>
              </v-col>
              <v-col cols="2">
                <label>
                  Дата выпуска
                  <custom-date-picker
                    v-model="info.release_date"
                    :visited.sync="fields_been_visited"
                    :has-changes.sync="hasChanges"
                  />
                </label>
              </v-col>
              <v-col cols="2">
                <label>
                  Объем двигателя
                  <v-text-field
                    ref="volume"
                    v-model="info.volume"
                    suffix="см³"
                    block
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    @input="(v) => (item.volume = v.replace(',', '.'))"
                    @change="(value) => replaceFloat({ value, prop: 'volume', afterPoint: 2 })"
                  />
                </label>
              </v-col>
              <v-col cols="2">
                <label>
                  Стоимость
                  <v-text-field
                    ref="invoice_cost"
                    v-model="info.invoice_cost"
                    block
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    @input="(v) => (item.invoice_cost = v.replace(',', '.'))"
                    @change="(value) => replaceFloat({ value, prop: 'invoice_cost', afterPoint: 2 })"
                  />
                </label>
              </v-col>
              <v-col cols="1">
                <label>
                  Код валюты
                  <v-autocomplete
                    v-model="info.invoice_currency_letter"
                    auto-select-first
                    :items="currencies"
                    item-text="text"
                    item-value="letterCode"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    :filter="filterBySearchField"
                  />
                </label>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import { mapGetters } from "vuex"
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import CustomDatePicker from "@/components/shared/custom-date-picker";
import { getCountryNameByCode, getTransportCodeByName } from "@/helpers/catalogs"
import { normalizeFloat } from "@/helpers/math"
import { eventBus } from "@/bus.js"

export default {
  components: {
    CustomDatePicker
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin],
  props: {
    item: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    },
    allTransportItems: {
      required: true,
      type: Array
    },
    transport: {
      required: true,
      type: Object
    },
    nsiTransportMarks: {
      required: true,
      type: Array
    },
  },
  data(){
    return {
      block_id: null,
      show: false,
      customVinMask: {
        mask: 'CCCCCCCCCCCCCCCCC',
        tokens: {
          'C': {
            pattern: /(?![QIOqio])[0-9a-zA-Z]/,
            transform: function(v) {
              return v.toLocaleUpperCase();
            }
          }
        }
      },
      transport_types: [
        {
          val: '10',
          text: '10-Морской/речной транспорт'
        },
        // {
        //   val: '20',
        //   text: '20-Железнодорожный транспорт'
        // },
        {
          val: '30',
          text: '30-Автодорожный транспорт'
        },
        {
          val: '40',
          text: '40-Воздушный транспорт'
        }
      ],
      info: {
        release_date: null,
        country_letter: null,
        invoice_cost: null,
        trailer: null,
        volume: null,
        number: null,
        invoice_currency_letter: null,
        transport_mark_code: null,
        vin_type: null,
        transport_mark_name: null,
        model_name: null,
        vehicle_type_code: null,
        vin: null,
        country_name: null,
      }
    }
  },
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      user: "auth/getUser",
      vehicleTypes:'catalogs/getNsiVehicleTypes',
      countries: "catalogs/getNsiCountries",
      currencies: "catalogs/getNsiCurrencies"
    }),
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `transport-item-${this.index + 1}`
        for (let key in newVal) {
          if (key in this.info) {
            this.info[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
    "info.vehicle_type_code"(nv){
      if(nv !== '30'){
        this.info.trailer = null
      }
    }
  },
  created(){
    eventBus.$on("openLastGood", (idx) => {
      if(idx === this.index){
        this.toggle()
      }
    })
  },
  methods: {
    replaceFloat({ value, prop, afterPoint = null }) {
      this.item[prop] = normalizeFloat({ value, afterPoint });
    },
    readyToUpdate(){
      this.hasChanges = true
      this.fields_been_visited = true
    },
    toggle(){
      this.show = !this.show
      this.$nextTick(() => {
        this.$scrollTo(`#${this.block_id}`, 200, { force: true, offset: -100 })
      })
    },
    uploadData() {
      const transports_json = [...this.allTransportItems]
      transports_json[this.index] = {
        ...this.info,
        country_name: getCountryNameByCode(this.countries, this.info.country_letter) || null,
        transport_mark_code: getTransportCodeByName(this.nsiTransportMarks, this.info.transport_mark_name) || null
      }

      const transport = this.convertEmptyStringsToNull({
        ...this.transport,
        transports_json: [
          ...transports_json,
        ],
      })
      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "transport",
        value: transport,
      })
      // .then(() => {
      //   this.$snackbar({text:"Обновлено [Транспортные средства]",color:"green", top:false, right: false});
      // })
    },
    //   addPart(){
    //     this.$emit('addPart')
    //   },
    deleteItem(){
      const transports_json = [...this.allTransportItems]
      transports_json.splice(this.index, 1)

      const transport = {
        ...this.transport,
        transports_json: [
          ...transports_json,
        ],
      }
      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "transport",
        value: transport,
      })
    },
    // uploadData() {
    //   allTransportItems
    //   this.$emit('updateItem', this.index, this.info)
    // }
  }
}
</script>

<style scoped>
.transportation-box {
  border-radius: 5px;
  margin: 0 0 10px 0;
  cursor: pointer;
}
.transportation-box__header {
  padding: 0px 10px;
  align-items: center;
  display: flex;
  color: white;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.transportation-box__header_open {
  background: #333333;
  border-radius: 6px 6px 0px 0px;
}
.transportation-box__header_open span {
  color: white;
}
.transportation-box__header_close {
  background: #f2f2f2;
  border-radius: 6px;
}
.transportation-box__header_close span {
  color: #333333;
}
.transition-box__params {
  font-size: 12px;
  color: black;
  width: 100%;
  padding: 10px 0;
}
.transition-box__param {
  transition: all ease-in-out 0.3s;
}
.transportation-box__header_close .transition-box__param {
  visibility: visible;
}

.transportation-box__header_open .transition-box__param {
  visibility: hidden;
}
.transition-box__params div:nth-child(1) {
  flex: 1;
}
.transition-box__params div:nth-child(2) {
  flex: 3;
}

.transition-box__params div:nth-child(3) {
  flex: 22;
}

.transition-box__params div:nth-child(4) {
  flex: 2;
}

.transition-box__params div:nth-child(5) {
  flex: 1;
}

.transition-box__params div:nth-child(6) {
  flex: 2;
}
.transition-box__params div:nth-child(7) {
  flex: 3;
}
.transition-box__params div:nth-child(8) {
  flex: 2;
}
.transition-box__params div:nth-child(9) {
  flex: 1;
}
.gradient {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f2f2f2;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 54%, #fafafa 64%);
  background-size: 1500px 50px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.v-divider{
  border-style: dashed;
}
</style>
