<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12">
        <div
          :id="block_id"
          v-click-outside="triggerOnFocus"
          class="statistics-box pa-lg-4"
        >
          <v-row>
            <v-col cols="12">
              <span class="font-weight-bold text-body-2">ФИО лица, не достигшего 16-летнего возраста</span>
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label>
                Страна
                <v-autocomplete
                  v-model="teenager_json.country_letter"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="text"
                  item-value="letterCode"
                  class="mr-1"
                  :filter="filterBySearchField"
                />
              </label>
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label> Код вида документа </label>
              <v-autocomplete
                v-model="teenager_json.identity_doc_name"
                :items="identityDocumentsFiltered"
                :filter="filterBySearchField"
                item-text="search"
                item-value="name"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>            
            <v-col
              cols="12"
              lg="2"
            >
              <label>
                Номер документа
                <v-text-field
                  v-model="teenager_json.identity_doc_number"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label>
                Дата выдачи
                <custom-date-picker
                  v-model="teenager_json.identity_doc_d_on"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                />
              </label>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            />
            <v-col
              cols="12"
              lg="2"
            >
              <label>Фамилия</label>
              <v-text-field
                v-model="teenager_json.last_name"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label>Имя</label>
              <v-text-field
                v-model="teenager_json.first_name"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label>
                Отчество
                <v-text-field
                  v-model="teenager_json.middle_name"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue"
import {getCountryNameByCode, getIdentityCodeByName} from "@/helpers/catalogs"

export default {
  components: {CustomDatePicker},
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: "passenger-teenager",
    teenager: {
      declaration_id: null,
    },
    teenager_json: {
      first_name: "",
      identity_doc_d_on: "",
      identity_doc_number: "",
      last_name: "",
      middle_name: "",
      country_letter: "",
      country_name: "",
      identity_doc_code: "",
      identity_doc_name: ""
    },
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      user: "auth/getUser",
      wares: "ptd/getSelectedWares",      
      countries: "catalogs/getNsiCountries",      
      identityDocuments: "catalogs/getNsiIdentityDocuments"
    }),    
    identityDocumentsFiltered() {
      if (this.teenager_json.country_letter) {
        if (['BY', 'RU', 'KZ', 'KG', 'AM'].includes(this.teenager_json.country_letter)) {
          return this.identityDocuments.filter(item => {
            return item.code.startsWith(this.teenager_json.country_letter)
          })
        }
        return this.identityDocuments.filter(item => {
          return item.code.startsWith('XX')
        })
      }
      return this.identityDocuments
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    }
  },
  methods: {
    setFields() {
      const teenager = this.selected.teenager;
      const teenager_json = this.selected.teenager.teenager_json

      Object.keys(this.teenager).forEach((key) => {
        if (key in teenager) {
          this.teenager[key] = teenager[key];
        }
      });

      Object.keys(this.teenager_json).forEach((key) => {
        if (key in teenager_json) {
          this.teenager_json[key] = teenager_json[key];
        }
      });
    },
    uploadData() {
      const teenager = this.convertEmptyStringsToNull({
        ...this.teenager,
        teenager_json: {
          ...this.teenager_json,
          country_name: getCountryNameByCode(this.countries, this.teenager_json.country_letter),
          identity_doc_code: getIdentityCodeByName(this.identityDocuments, this.teenager_json.identity_doc_name)
        },
      })

      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "teenager",
        value: teenager,
      });
    },
  }
};
</script>
