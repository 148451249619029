<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="d-flex align-center mt-2"
    style="gap:20px;"
  >
    <div class="t-check">
      <v-checkbox
        tabindex="-1"
        dense
        hide-details="true"
        color="#5CB7B1"
        :value="selectedDocs.includes(index)"
        @change="updateSelected"
      />
    </div>
    <div
      class="t-index"
      style="width:60px;"
    >
      <v-text-field
        :value="index + 1"
        outlined
        disabled
        dense
        hide-details="auto"
        background-color="#D3D3D3"
      />
    </div>
    <div
      class="t-number"
      style="width:220px;"
    >
      <v-text-field
        v-model="part.number"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div
      class="t-descr"
      style="width: 66%;"
    >
      <v-text-field
        v-model="part.description"
        block
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div
      class="t-add d-flex"
      style="width: 72px;"
    >
      <v-btn
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="deletePart"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === transportPartsLength - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="addPart"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import { mapGetters } from "vuex"

export default {
  mixins: [blockAutoUpdate],
  props: {
    item: {
      required: true,
      type: Object
    },
    transportPartsLength: {
      required: true,
      type: Number
    },
    index: {
      required: true,
      type: Number
    },
    allParts: {
      required: true,
      type: Array
    },
    transportPart: {
      required: true,
      type: Object
    },
    selectedDocs: {
      required: true,
      type: Array
    }
  },
  data(){
    return {
      block_id: null,
      part: {
        number: "",
        description: ""
      }
    }
  },
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      user: "auth/getUser",
    }),
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `transport-part-${this.index + 1}`
        for (let key in newVal) {
          if (key in this.part) {
            this.part[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    updateSelected(){
      this.$emit('select', this.index)
    },
    addPart(){
      this.$emit('addPart')
    },
    deletePart(){
      this.$emit('deletePart', this.index)
    },
    uploadData() {
      const parts_json = [...this.allParts]
      parts_json[this.index] = {
        ...this.part
      }

      const transport_part = this.convertEmptyStringsToNull({
        ...this.transportPart,
        transport_parts_json: [...parts_json]
      })
      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "transport_part",
        value: transport_part,
      })
      //   .then(() => {
      //     this.$snackbar({text:"Обновлено [Часть транспортного средства]",color:"green", top:false, right: false});
      //   })
    },
  }
}
</script>
