<template>
  <v-overlay :value="show">
    <v-card
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <div class="d-flex justify-space-between py-3 px-5">
        <h3 class="py-2">
          Невозможно продолжить работу с документом
        </h3>
      </div>
      <div>
        <v-col class="white py-4">
          <div class="rounded red white--text pa-4">
            {{ message }}
          </div>
        </v-col>
      </div>
      <div class="d-flex justify-end">
        <v-btn
          dark
          color="#5CB7B1"
          class="mx-5 mt-5"
          @click="toJournal"
        >
          Перейти в журнал
        </v-btn>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import {DIVISION_CHANGE_EVENT} from "@/helpers/broadcast";

export default {
  inject: ['rootNamespace'],
  data: () => ({
    show: false,
    message: null
  }),
  computed: {
    selectedDocumentDivisionId() {
      return this.$store.getters[`${this.rootNamespace}/getSelected`].division_id
    }
  },
  created() {
    this.subscribeOnDivisionChange()
  },
  methods: {
    subscribeOnDivisionChange() {
      this.$broadcastChannel.onmessage = (event) => {
        const type = event.data.type
        switch (type) {
        case DIVISION_CHANGE_EVENT:
          this.notifyOnDistinctDivision();
          break;
        }
      }
    },
    toJournal() {
      this.$router.replace('/journal')
    },
    getActiveDivisionId() {
      const user = this.$store.getters['auth/getUser']
      return user.user.activeDivisionId
    },
    setNotificationMessage(requiredDivisionId, currentDivisionId) {
      const requiredDivisionName = this.getDivisionNameById(requiredDivisionId)
      const currentDivisionName = this.getDivisionNameById(currentDivisionId)
      this.message = `Для продолжения выберите отдел "${requiredDivisionName}". Текущий отдел - ${currentDivisionName}`
    },
    getDivisionNameById(divisionId) {
      const division = this.$store.getters['auth/getDivisions'].find(division => division.id === divisionId)
      return division?.division_name
    },
    notifyOnDistinctDivision() {
      const activeDivisionId = this.getActiveDivisionId()
      if (this.selectedDocumentDivisionId && activeDivisionId !== this.selectedDocumentDivisionId) {
        this.show = true
        this.setNotificationMessage(this.selectedDocumentDivisionId, activeDivisionId)
      } else if (this.selectedDocumentDivisionId && activeDivisionId === this.selectedDocumentDivisionId) {
        this.show = false
      }
    }
  }
}
</script>