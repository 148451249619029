<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <div class="font-weight-bold d-flex align-center mt-2">
          Сведения о предполагаемом использовании наличных денежных средств и (или) денежных инструментов
        </div>
      </v-col>

      <v-col cols="3">
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Текущие расходы (приобретение товаров и услуг)</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="money_purpose_json.shopping"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Инвестиции, включая приобретение недвижимости</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="money_purpose_json.investment"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Безвозмездные трансферты в пользу физических лиц (материальная помощь и т.п.)</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="money_purpose_json.individual_person_donate"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Безвозмездные трансферты в пользу юридических лиц (благотворительность, пожертвования и т.п.)</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="money_purpose_json.organization_donate"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>

      <v-col cols="12">
        <div class="radio-card pa-4">
          <label>
            Прочее (указать)
            <v-textarea
              ref="description"
              v-model="money_purpose_json.description"
              outlined
              auto-grow
              dense
              row-height="30"
              hide-details="auto"
              background-color="white"
              rows="3"
            />
          </label>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";

export default {
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: "passenger-money-purpose",
    money_purpose: {
      declaration_id: null,
    },
    money_purpose_json: {
      description: "",
      individual_person_donate: "",
      investment: "",
      organization_donate: "",
      shopping: "",
    }
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected"
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    }
  },
  methods: {
    readyToUpdate(){
      this.hasChanges = true
      this.fields_been_visited = true
    },
    setFields() {
      const money_purpose = this.selected.money_purpose;
      const money_purpose_json = this.selected.money_purpose.money_purpose_json;

      Object.keys(this.money_purpose).forEach((key) => {
        if (key in money_purpose) {
          this.money_purpose[key] = money_purpose[key];
        }
      });

      Object.keys(this.money_purpose_json).forEach((key) => {
        if (key in money_purpose_json) {
          this.money_purpose_json[key] = money_purpose_json[key];
        }
      });
    },
    uploadData() {
      const money_purpose = this.convertEmptyStringsToNull({
        ...this.money_purpose,
        money_purpose_json: {
          ...this.money_purpose_json,
        },
      })
      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "money_purpose",
        value: money_purpose,
      });
    },
  }
};
</script>

<style scoped>
.radio-card{
    background-color: #ededed;
    border-radius: 5px;
}
.radio-card-body{
    min-height: 95px;
    font-size: 14px !important;
}
</style>
