<template>
  <v-row>
    <v-col
      cols="6"
      class="pb-0"
    >
      <label>
        Код и наимен-ие вида связи
        <v-autocomplete
          ref="code"
          v-model="cont_item.code"
          auto-select-first
          :items="types"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          item-text="text"
          item-value="code"
          @change="updatedField"
        />
      </label>
    </v-col>
    <v-col
      cols="6"
      class="pb-0"
    >
      <label>
        Номер
        <v-text-field
          ref="number"
          v-model="cont_item.number"
          v-mask="'+375 ## #######'"
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="updatedField"
        />
      </label>
    </v-col>
  </v-row>
</template>

<script>
import { filler as onFiller } from '@/events/statistics/control'
import { highlightField } from '@/helpers/control'
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      required: true,
      type: Object
    },
    idx: {
      required: true,
      type: Number
    },
    types: {
      required: true,
      type: Array
    },
    contactsLength: {
      required: true,
      type: Number
    }
  },
  data:() => ( {   
    cont_item: {
      code: "",
      name: "",
      number: ""
    }
  }),
  computed: {
    ...mapGetters({
      selected: 'transit/getSelected'
    })
  },
  watch: {
    item: {
      handler(newVal) {
        for (let key in newVal) {
          if (key in this.cont_item) {
            this.cont_item[key] = newVal[key];
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created(){
    onFiller.subscribe(this.highlightField)
  },
  methods: {
    highlightField,
    deleteContactItem(){
      this.$emit('deleteContactItem', this.idx)
    },
    addContactItem(){
      this.$emit('addContactItem')
    },
    updatedField(){
      this.$emit('contactsUpdater', {cont_item: this.cont_item, index: this.idx})
    }
  }
}
</script>