<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <div class="font-weight-bold d-flex align-center mt-2">
          Сведения об источнике происхождения наличных денежных средств и (или) денежных инструментов
        </div>
      </v-col>
      <v-col cols="3">
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Заработная плата, доходы от предпринимательской деятельности физического лица</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="money_source_json.salary"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Дивиденды и другие доходы от участия в капитале предприятий</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="money_source_json.dividends"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Доходы от реализации движимого и недвижимого имущества</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="money_source_json.sale"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Безвозмездные трансферты в пользу физических лиц (материальная помощь и т.п.)</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="money_source_json.financial_assistance"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Пенсия, стипендия, социальные пособия, алименты</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="money_source_json.social_benefits"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Доходы от аренды недвижимости и земельных участков</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="money_source_json.territory_rent"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Заемные средства</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="money_source_json.loan"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Наследство</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="money_source_json.heritage"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="12">
        <div class="radio-card pa-4">
          <label>
            Прочее (указать)
            <v-textarea
              ref="description"
              v-model="money_source_json.description"
              outlined
              auto-grow
              dense
              row-height="30"
              hide-details="auto"
              background-color="white"
              rows="3"
            />
          </label>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";

export default {
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: "passenger-money-source",
    money_source: {
      declaration_id: null,
    },
    money_source_json: {
      description: "",
      dividends: "",
      financial_assistance: "",
      heritage: "",
      loan: "",
      salary: "",
      sale: "",
      social_benefits: "",
      territory_rent: ""
    }
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected"
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    }
  },
  methods: {
    readyToUpdate(){
      this.hasChanges = true
      this.fields_been_visited = true
    },
    setFields() {
      const money_source = this.selected.money_source;
      const money_source_json = this.selected.money_source.money_source_json;

      Object.keys(this.money_source).forEach((key) => {
        if (key in money_source) {
          this.money_source[key] = money_source[key];
        }
      });

      Object.keys(this.money_source_json).forEach((key) => {
        if (key in money_source_json) {
          this.money_source_json[key] = money_source_json[key];
        }
      });
    },
    uploadData() {
      const money_source = this.convertEmptyStringsToNull({
        ...this.money_source,
        money_source_json: {
          ...this.money_source_json,
        },
      })
      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "money_source",
        value: money_source,
      });
    },
  }
};
</script>

<style scoped>
.radio-card{
    background-color: #ededed;
    border-radius: 5px;
}
.radio-card-body{
    min-height: 95px;
    font-size: 14px !important;
}
</style>
