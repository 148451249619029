<template>
  <v-row>
    <v-col cols="2">
      <label>
        Код товара
        <v-menu offset-x>
          <template #activator="{ on, attrs }">
            <v-icon
              tabindex="-1"
              class="mx-1"
              small
              dense
              v-bind="attrs"
              v-on="on"
            >mdi-tune</v-icon>
          </template>
          <v-list>
            <v-list-item @click="showTNVED">
              Поиск в справочнике
            </v-list-item>
            <!-- <v-list-item @click="applyAllDialog = { field: 'tn_ved', value: item.tn_ved }, applyAllDialogBoolean = true">
              Применить ко всем товарам
            </v-list-item>
            <v-list-item @click="applyParam('tn_ved', item.tn_ved, 'apply')">
              Применить к выбранным товарам
            </v-list-item> -->
          </v-list>
        </v-menu>
        <div class="d-flex">
          <v-text-field
            ref="tn_ved"
            v-model="item.tn_ved"
            v-mask="'##########'"
            disabled
            outlined
            dense
            hide-details="auto"
            background-color="grey lighten-2"
            @change="tnvedChanged"
            @keypress.enter="showTNVED"
          />
        </div>
      </label>
    </v-col>
    <v-col
      cols="2"
      offset="1"
    >
      <label>
        Товарный знак
      </label>
      <div class="d-flex">
        <v-text-field
          v-model="item.trade_mark"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </div>
    </v-col>
    <v-col cols="2">
      <label>
        Марка
        <div class="d-flex">
          <v-text-field
            v-model="item.mark_name"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </div>
      </label>
    </v-col>
    <v-col cols="2">
      <label>
        Модель
        <div class="d-flex">
          <v-text-field
            v-model="item.model_name"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </div>
      </label>
    </v-col>
    <v-col cols="2">
      <label>
        Идентификатор продукта
        <div class="d-flex">
          <v-text-field
            v-model="item.product_id"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </div>
      </label>
    </v-col>
    <!--
    <v-col
      class="d-flex align-end "
    >
      <div class="white rounded flex-grow-1">
        <v-tabs
          v-model="tab"
          optional
        >
          <v-tab
            v-for="(tab, idx) in tabs"
            :key="'tab' + (idx + 1)"
            tabindex="-1"
          >
            {{ tab.title }}
          </v-tab>
          <v-btn
            x-large
            tile
            plain
            class="pb-1"
            tabindex="-1"
            @click="showCodeInfo"
          >
            <span class="show-tnved"> ИНФОРМАЦИЯ О КОДЕ</span>
          </v-btn>
        </v-tabs>
      </div>
    </v-col> -->

    <!-- <v-col
      cols="12"
      class="py-0"
    >
      <v-tabs
        id="document-goods-tabs"
        v-model="tab"
      >
        <v-tab-item
          v-for="tab in tabs"
          :key="tab.title"
        >
          <component
            :is="tab.component"
            :code="code"
          />
        </v-tab-item>
      </v-tabs>
    </v-col> -->
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { showCatalog, catalogValueSelected, tnvedCodeSelected, tnvedCodeChanged } from "@/events/statistics";
import cloneDeep from 'lodash.clonedeep'
// import CustomDatePicker from '@/components/shared/custom-date-picker.vue'
// import {highlightField} from '@/helpers/control'

export default {
//   components: {CustomDatePicker},
  props: {
    index: {
      required: true,
      type: Number,
    },
    info:{
      type:Object,
      required:true,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
    // control:{
    //   required:true,
    //   type:Object,
    // },
  },
  data:() => ({
    item:{
      id: "",
      tn_ved: "",
      declaration_id: "",
      product_id: "",
      mark_name: "",
      trade_mark: "",
      model_name: ""
    },
    code: "",
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      selected_wares: "ptd/getSelectedWares",
      transport_cat: "catalogs/getNsiTransportCategories"
    })
  },
  watch: {
    selected_wares: {
      handler() {
        this.setFields();
      },
      immediate: true,
      deep: true,
    },
    item: {
      handler() {
        // Пропс visited обвновляется позже чем вызывается обработчик,
        // поэтому нужно свойство внутри компонента
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
    // control:{
    //   handler({path}){
    //     if(path !== ""){
    //       highlightField.call(this, {path})
    //     }
    //   },
    //   deep:true,
    //   immediate:true
    // },
  },
  created(){
    tnvedCodeSelected.subscribe(this.checkAndUpdateTNVED)
    catalogValueSelected.subscribe(this.setTransport, [
      'item.transport_category',
    ])
  },
  beforeDestroy(){
    tnvedCodeSelected.unsubscribe()
  },
  methods: {
    setTransport(param){
      this.item.transport_category = param.code
      this.$emit('update:visited', true)
      this.$emit('update:has-changes',true)
    },
    openTransport() {
      showCatalog.trigger({
        type: "transport_cat",
        search: this.item.transport_category,
        field:"item.transport_category" });
    },
    checkAndUpdateTNVED({code, id}) { // Событие при выборе кода из справочника
      if(id === this.item.id){
        this.item.tn_ved = code;
        this.code = code;
        tnvedCodeChanged.trigger({ id: this.item.id, code: this.code });
        this.$emit('update:visited', true)
        this.$emit('update:has-changes',true)
      }
    },
    tnvedChanged(){ // Событие при "выходе" из инпута
      this.code = this.item.tn_ved
      tnvedCodeChanged.trigger({ id: this.item.id, code: this.code });
    },
    updateEditableGoodsItem() {
      this.$emit('update:info', this.item)
      if (this.tn_ved_selected === true) this.tn_ved_selected = false;
    },
    showTNVED() {
      showCatalog.trigger({
        type: "tnved",
        id: this.item.id,
        search: this.item.tn_ved,
      });
    },
    setFields(){
      const ware = cloneDeep(this.selected_wares[this.index])
      Object.keys(this.item).forEach(key => {
        if(key in ware){
          this.item[key] = ware[key]
        }
      })
      this.code = this.info.tn_ved;
    },
  }
}
</script>
<style scoped>
.show-tnved {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
</style>
