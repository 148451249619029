export default {
  methods:{
    customFieldChecker(items, field, param) {
      if(field === null){
        return false
      }
      var positiveArr = items.filter(function(i) {
        return i[param] === field;
      })
      if(positiveArr.length || field === '' ){
        return true
      }else{
        return false
      }
    },
    customFieldCheckerNull(items, field, param) {
      if(field === null){
        return false
      }else if(field === ''){
        return true
      }else{
        var positiveArr = items.filter(function(i) {
          return i[param] === field;
        })
        if(positiveArr.length){
          return false
        }else{
          return true
        }
      }
    },
  }
}
  