<template>
  <div
    v-intersect="onIntersect"
    class="transportation-box rs-goods-item"
  >
    <div
      class="transportation-box__header"
      :class="{
        'transportation-box__header_close': !show,
        'transportation-box__header_open': show,
        gradient: !visible,
      }"
    >
      <div
        class="d-flex align-center transition-box__params"
        @click.capture="toggle"
      >
        <div>
          <span
            v-if="visible"
            class="font-weight-bold"
          >  {{ info.sort_index }}</span>
        </div>

        <div
          cols="2"
          class="transition-box__param font-weight-bold"
        >
          <span v-if="visible && info.tn_ved">{{ info.tn_ved }} <span class="teal--text">{{ info.add_tn_ved }}</span></span>
        </div>
        <div class="transition-box__param mr-4">
          <p
            v-if="visible"
            class="my-auto"
          >
            {{ title }}
          </p>
        </div>
      </div>
      <div class="d-flex">
        <v-btn
          max-height="32px"
          min-width="32px"
          max-width="32px"
          class="float-right ware-item-toggle"
          @focus.prevent
          @click.prevent="toggle"
        >
          <v-icon>
            {{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>

        <v-menu
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-btn
              tabindex="-1"
              max-height="32px"
              min-width="32px"
              max-width="32px"
              color="#5CB7B1"
              v-bind="attrs"
              class="ml-2 wares-item-menu"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              class="wares-item-menu-delete"
              @click="deleteItem"
            >
              Удалить
            </v-list-item>
            <v-list-item
              class="wares-item-menu-copy"
              @click="copyItem"
            >
              Копировать
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div
      v-if="render"
      :id="block_id"
      v-click-outside="triggerOnFocus"
      class="editable-content"
    >
      <v-expand-transition>
        <div v-show="show">
          <div
            class="statistics-box pa-4"
          >
            <code-block
              :index="index"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              :info="info"
              @update:info="updateInfoInComponent"
            />
            <description
              :index="index"
              :info="info"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              @update:info="updateInfoInComponent"
            />
            <character
              :index="index"
              :info="info"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              @update:info="updateInfoInComponent"
            />
          </div>
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CodeBlock from "./code.vue"
import Description from "./description.vue"
import Character from './character.vue';
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import formatDate from '@/mixins/format-date.js'
import { getMeasureUnitShortName } from "@/helpers/catalogs"
import { eventBus } from "@/bus.js"
import {
  forceUpdateWareDetail,
} from "@/events/statistics";
import moment from "moment"
import cloneDeep from 'lodash.clonedeep'
import { convertEmptyStringsToNull } from '@/helpers/objects';

export default {
  components: {
    CodeBlock,
    Description,
    Character,
  },
  mixins: [blockAutoUpdate, formatDate],
  props: {
    item: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
    // nsiTransportMarks: {
    //   required: true,
    //   type: Array,
    // },
    // blockedvis: {
    //   required: true,
    //   type: Boolean
    // }
  },
  data: () => ({
    show: false,
    block_id: null,
    is_goods_item: true,
    info: {},
    title: "",
    wareDetails: [],
    render: false,
    isIntersecting: false,
    promises:[],
    promisesWares:[],
    onWares:null,
    waresControlPath:{
      path:"",
      timestamp:null
    },
    addMeasureLetterInput:null,
    availableExcises: {},
  }),
  computed: {
    ...mapGetters({
      selected: 'ptd/getSelected',
      measure_units: 'catalogs/getNsiMeasureUnits'
    }),
    customsIndex() {
      const value = (this.info.statistical_cost / this.info.net_weight).toFixed(2)
      if(!isNaN(value) && this.info.statistical_cost && this.info.net_weight){
        return `${value}`
      }
      return "0"
    },
    visible() {
      return this.index < 16 || this.isIntersecting;
    },
    showWareDetailQuantity(){
      return !!(this.info.add_measure_unit_letter || document.activeElement === this.addMeasureLetterInput) || false
    }
  },
  watch: {
    index(){
      this.block_id = `passenger-goods-item-${this.index}`
    },
    item: {
      handler(nv) {
        this.info = cloneDeep(nv);
        this.block_id = `passenger-goods-item-${this.index}`;
        if (this.visible) {
          this.setTitle();
        }
      },
      immediate: true,
      deep: true,
    },
    // // Начинаем вычислять когда товар попадает в область видимости
    visible: {
      handler(nv) {
        if (nv === true) this.setTitle();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    eventBus.$on("openLastGood", (idx) => {
      if(idx === this.index){
        this.toggle()
      }
    })
  },
  methods: {
    onComponentPromise({id,promise, type = "block"}){
      if(id === this.info.id){
        type === "ware" ? this.promisesWares.push(promise) : this.promises.push(promise)
      }
    },
    onAddMeasureChanged({value}){ // См. Статистику
      if((!value || value === "") && this.wareDetails.length){
        const updated = this.wareDetails.filter(i =>
          i.add_measure_unit_quantity !== null &&
          i.add_measure_unit_quantity !== "")
          .map(i => {
            return {
              ...i,
              add_measure_unit_quantity:null
            }
          })
        if(!updated.length) return;
        this.$store.dispatch('ptd/updateWareDetail', updated)
      }
    },
    getShipmentDate(date){
      return date ? moment(date).format('DD.MM.YYYY') :""
    },
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    },
    setTitle() {
      let str = this.info?.title || "";
      if (str.length - 1 > 170) {
        str = `${str.slice(0, 170)} ...`;
      }
      this.title = str;
    },
    checkAndSetVisited({ index, action }) {
      if (this.index === index) {
        this.updateWareDetails(action);
        this.fieldsBeenVisitedByUser();
        this.triggerOnChange();
        if (["add", "delete"].includes(action)) {
          this.updateEventListeners();
        }
      }
    },
    toggle() {
      if (this.render === true) {
        this.show = false;
        this.destroyEventListeners();
        this.$nextTick(() => {
          this.render = false;
        });
      } else {
        if (this.info.id) {
          this.$store.commit("ptd/SET_EDITABLE_WARE", {
            id: this.info.id,
            index: this.index,
          });
        }
        this.render = true;
        this.$nextTick(() => {
          // this.fetchWareDetails();
          this.setEventListeners();
          // this.getAvailableExcises()
          this.show = true;
          this.$scrollTo(`#${this.block_id}`, 200, { force: true, offset: -100 })
          // this.setAddMeasureField()
        });
      }
    },
    updateInfoInComponent(data) {
      this.info = {
        ...this.info,
        ...data,
      };
    },
    uploadData() {
      return Promise.all(this.promises).then(() => {
        this.promises = []
        forceUpdateWareDetail.trigger({ id: this.info.id });
        return Promise.all(this.promisesWares)
      }).then(() => {
        this.promisesWares = []
        const value = convertEmptyStringsToNull({
          ...this.info,
          measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.info.measure_unit_digit)
        })
        return this.$store.dispatch("ptd/updateGoodsItem", value);
      })
    },
    deleteItem() {
      const { id, sort_index } = this.info;
      const index = this.index;
      this.$store.dispatch("ptd/deleteGoodsItem", {
        id,
        sort_index,
        index,
      }).then(() => {
        this.$snackbar({text:'Товар удален', top:false, right: false})
      }).catch(() => {
        this.$snackbar({text:`Ошибка удаления товара`, top:false, right: false,color:'red'})
      });
    },
    readyToUpdate(){
      this.hasChanges = true
      this.fields_been_visited = true
    },
    copyItem() {
      const {id} = this.item
      this.$store.dispatch('ptd/copyWare', {id}).then(() => {
        this.$snackbar({text: 'Товар скопирован', top: false, right: false})
      }).catch((err) => this.$error(`Не удалось скопировать товар. ${err.response.data}`)
      )
    }
  },
};
</script>

<style scoped>
.transportation-box {
  border-radius: 5px;
  margin: 0 0 10px 0;
  cursor: pointer;
}
.transportation-box__header {
  padding: 0 10px;
  align-items: center;
  display: flex;
  color: white;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.transportation-box__header_open {
  background: #333333;
  border-radius: 6px 6px 0px 0px;
}
.transportation-box__header_open span {
  color: white;
}
.transportation-box__header_close {
  background: #f2f2f2;
  border-radius: 6px;
}
.transportation-box__header_close span {
  color: #333333;
}
.transition-box__params {
  font-size: 12px;
  color: black;
  width: 100%;
  padding: 10px 0;
}
.transition-box__param {
  transition: all ease-in-out 0.3s;
}
.transportation-box__header_close .transition-box__param {
  visibility: visible;
}

.transportation-box__header_open .transition-box__param {
  visibility: hidden;
}
.transition-box__params div:nth-child(1) {
  flex: 1;
}
.transition-box__params div:nth-child(2) {
  flex: 3;
}

.transition-box__params div:nth-child(3) {
  flex: 22;
}

.transition-box__params div:nth-child(4) {
  flex: 2;
}

.transition-box__params div:nth-child(5) {
  flex: 1;
}

.transition-box__params div:nth-child(6) {
  flex: 2;
}
.transition-box__params div:nth-child(7) {
  flex: 3;
}
.transition-box__params div:nth-child(8) {
  flex: 2;
}
.transition-box__params div:nth-child(9) {
  flex: 1;
}
.gradient {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f2f2f2;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 54%, #fafafa 64%);
  background-size: 1500px 50px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.v-divider{
  border-style: dashed;
}
</style>
