<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="d-flex align-center mt-2"
    style="gap:20px;"
  >
    <div class="t-check">
      <v-checkbox
        tabindex="-1"
        dense
        hide-details="true"
        color="#5CB7B1"
        :value="selectedDocs.includes(index)"
        @change="updateSelected"
      />
    </div>
    <div
      class="t-index"
      style="width:60px;"
    >
      <v-text-field
        :value="index + 1"
        outlined
        disabled
        dense
        hide-details="auto"
        background-color="#D3D3D3"
      />
    </div>
    <div
      class="t-number"
      style="width:220px;"
    >
      <v-text-field
        v-model="cash.sum"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div
      class="t-descr"
      style="width: 120px;"
    >
      <!-- <v-text-field
        v-model="cash.currency_letter"
        block
        outlined
        dense
        hide-details="auto"
        background-color="white"
      /> -->
      <v-autocomplete
        v-model="cash.currency_letter"
        auto-select-first
        :items="currencies"
        item-text="letterCode"
        item-value="letterCode"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :filter="filterBySearchField"
      />
    </div>
    <div
      class="t-add d-flex"
      style="width: 72px;"
    >
      <v-btn
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="deletePart"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === moneyItemsLength - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="addPart"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import { mapGetters } from "vuex"

export default {
  mixins: [blockAutoUpdate, filterBySearchFieldMixin],
  props: {
    item: {
      required: true,
      type: Object
    },
    moneyItemsLength: {
      required: true,
      type: Number
    },
    index: {
      required: true,
      type: Number
    },
    allMoney: {
      required: true,
      type: Array
    },
    // money
    money: {
      required: true,
      type: Object
    },
    selectedDocs: {
      required: true,
      type: Array
    }
  },
  data(){
    return {
      block_id: null,
      cash: {
        sum: "",
        currency_letter: ""
      }
    }
  },
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      user: "auth/getUser",
      currencies: "catalogs/getNsiCurrencies"
    }),
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `money-cash-${this.index + 1}`
        for (let key in newVal) {
          if (key in this.cash) {
            this.cash[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    updateSelected(){
      this.$emit('select', this.index)
    },
    addPart(){
      this.$emit('addPart')
    },
    deletePart(){
      this.$emit('deletePart', this.index)
    },
    uploadData() {
      const cash_json = [...this.allMoney]
      cash_json[this.index] = {
        ...this.cash
      }

      const money = this.convertEmptyStringsToNull({
        ...this.money,
        money_json: [...cash_json]
      })

      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "money",
        value: money,
      })
      // .then(() => {
      //   this.$snackbar({text:"Обновлено [Часть транспортного средства]",color:"green", top:false, right: false});
      // })
    },
  }
}
</script>
