<template>
  <v-row>
    <v-col
      cols="12"
      class="pb-0"
    >
      <span
        class="font-weight-bold text-body-2"
      >Описание товаров</span>
    </v-col>


    <v-col cols="12">
      <v-textarea
        v-model="item.title"
        outlined
        auto-grow
        dense
        row-height="50"
        hide-details="auto"
        background-color="white"
        rows="2"
        :counter="1000"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import cloneDeep from 'lodash.clonedeep'
// import { getMeasureUnitShortName, getTransportCodeByName } from '@/helpers/catalogs'
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
// import { convertEmptyStringsToNull } from '@/helpers/objects'
import formatDate from '@/mixins/format-date.js'
import customFieldChecker from '@/mixins/custom-field-checker'
// import {highlightField} from '@/helpers/control'

export default {
  mixins: [filterBySearchFieldMixin, formatDate, customFieldChecker],
  props: {
    index: {
      required: true,
      type: Number,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
    // wareDetails: {
    //   required: true,
    //   type: Array,
    // },
    // showQuantity:{
    //   required: true,
    //   type: Boolean,
    // },
    info:{
      type:Object,
      required:true
    },
    // control:{
    //   required:true,
    //   type:Object,
    // },
    // nsiTransportMarks: {
    //   required:true,
    //   type:Array,
    // }
  },
  data: () => ({
    item: {
      id: "",
      declaration_id: "",
      title: "",
    },
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      selected_wares: "ptd/getSelectedWares",
    //   measure_units: "catalogs/getNsiMeasureUnits",
    //   transport: "catalogs/getNsiTransportOtherCategories"
    }),
  },
  watch: {
    selected_wares: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
    item: {
      handler() {
        // Пропс visited обвновляется позже чем вызывается обработчик,
        // поэтому нужно свойство внутри компонента
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
    // control:{
    //   handler({path}){
    //     if(path !== ""){
    //       highlightField.call(this, {path})
    //     }
    //   },
    //   deep:true,
    //   immediate:true
    // },
  },
  methods: {
    // replaceToInt({value, prop}){
    //   if(value.includes('.')){ //Если не целое число
    //     const int = Number.parseInt(value)
    //     return this.item[prop] = int
    //   }
    //   return this.item[prop] = value
    // },
    // replaceFloatNumbers({value, prop}){
    //   if(value.includes('.')){ //Если не целое число
    //     let result = ""
    //     const float = Number.parseFloat(value)
    //     if(value >= 0.001){
    //       result = float.toFixed(3).replace(/0*$/,"").replace(/\.*$/,"") // Убираем лишние нули в конце и если нужно точку
    //     }else{
    //       result = float.toFixed(6).replace(/0*$/,"").replace(/\.*$/,"")
    //     }
    //     return this.item[prop] = result
    //   }
    //   return this.item[prop] = value
    // },
    setFields() {
      const ware = cloneDeep(this.selected_wares[this.index])
      Object.keys(this.item).forEach(key => {
        if(key in ware){
          this.item[key] = ware[key]
        }
      })
    },
    updateEditableGoodsItem() {
      this.$emit('update:info', this.item)
      if (this.tn_ved_selected === true) this.tn_ved_selected = false;
    },
    // updateEditableGoodsItem() {
    //   const payload = {
    //     ...this.item,
    //     volume_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.volume_measure_unit_digit),
    //     weight_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.weight_measure_unit_digit),
    //     power_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.power_measure_unit_digit),
    //     vehicle_type_code: getTransportCodeByName(this.transport, this.item.vehicle_type_name)
    //   }
    //   this.$emit('update:info', payload)
    //   if (this.tn_ved_selected === true) this.tn_ved_selected = false;
    // },
  },
}
</script>

<style scoped>
th{
  font-size: 10px !important;
}
td{
  font-size: 12px !important;
}
.v-divider{
  border-style: dashed;
}
</style>
