<template>
  <div>
    <div
      id="pinned-block"
      class="pinned"
      :class="{'py-3':slides.length}"
    >
      <swiper
        ref="mySwiper2"
        :options="swiperOptions"
        @ready="getSliderIndex"
        @slideChange="getSliderIndex"
      >
        <template
          v-for="s in slides"
        >
          <swiper-slide :key="s.name">
            <component
              :is="s.component"
            />
          </swiper-slide>
        </template>
      </swiper>
      <v-btn
        v-show="activeIndex > 0"
        dark
        class="pin-prev"
      >
        <v-icon x-large>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn
        v-show="activeIndex < slides.length - 1"
        dark
        class="pin-next"
      >
        <v-icon x-large>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {swipeToPinnedComponent} from '@/events/common'

export default {
  props:{
    tabs:{
      required:false,
      type:Array,
      default:() => []
    }
  },
  data:() => ({
    active:[],
    activeIndex: 0,
    swiperOptions: {
      slidesPerView: 'auto',
      centeredSlides: true,
      touchRatio: false,
      draggable: true,
      loop: false,
      simulateTouch: false,
      speed: 600,
      navigation: {
        nextEl: '.pin-next',
        prevEl: '.pin-prev',
      },
    },

  }),
  computed:{
    ...mapGetters({
      pinned:'ui/getPinned'
    }),
    slides(){
      return this.tabs.filter(i => this.pinned.includes(i.name)) || []
    },
  },
  created(){
    swipeToPinnedComponent.subscribe(this.swipeTo)
  },
  beforeDestroy(){
    swipeToPinnedComponent.unsubscribe()
  },
  methods:{
    swipeTo({pin}){
      if(this.pinned.includes(pin)){
        const activeTabsNames = this.slides.map(i => i.name)
        this.activeIndex = activeTabsNames.indexOf(pin)
        this.changeIndex()
      }
    },
    changeIndex() {
      this.$refs.mySwiper2.$swiper.activeIndex = this.activeIndex
    },
    getSliderIndex() {
      if (this.activeIndex === 0) {
        this.$refs.mySwiper2.$swiper.slideTo(1, 1)
      }   
      this.activeIndex = this.$refs.mySwiper2.$swiper.activeIndex
    },
  },
}
</script>
<style scoped>
    .slide{
        max-width: 1440px;
        margin: auto;
    }
    .swiper-container{
        height: auto
    }
    .pinned{
        overflow: hidden;
    }
    .swiper-slide-prev:before,.swiper-slide-next:before{
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
    .swiper-slide{
        opacity: .5;
        transform: translateY(100px);
        transition: all ease-in-out .3s;
        width: 1440px!important;
    }
    .swiper-slide-active{
        opacity: 1;
        transform: translateY(0);
    }
    .pin-next, .pin-prev{
      position: absolute;
      z-index: 50;
      cursor: pointer;
      display: block;
      transform: translateY(-50%);
      top: 50%;
    }
    .pin-next{
      right: 0;
    }
    .pin-prev{
      left: 0;
    }
</style>
