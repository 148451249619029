<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12">
        <div
          :id="block_id"
          v-click-outside="triggerOnFocus"
          class="statistics-box pa-4"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="font-weight-bold d-flex align-center mt-2">
                Денежные средства и (или) денежные инструменты
              </div>
            </v-col>
            <v-col
              cols="8"
            >
              <label>Наличные денежные средства(дорожные чеки) свыше 10000 долларов США в эквиваленте (на 1 лицо), векселя, чеки, ценные бумаги</label>
              <v-radio-group
                v-model="about_money.money_exists"
                hide-details="true"
                class="mt-2"
                @change="changeVisibility(about_money.money_exists)"
              >
                <v-radio
                  class="mb-0"
                  label="Да"
                  :value="true"
                  color="#5CB7B1"
                />
                <v-radio
                  class="mb-0"
                  label="Нет"
                  :value="false"
                  color="#5CB7B1"
                />
              </v-radio-group>
            </v-col>
            <v-col
              cols="3"
              offset="1"
            >
              <label>
                Валюта и Общая сумма
                <v-text-field
                  :value="totalCost"
                  outlined
                  dense
                  disabled
                  background-color="grey lighten-2"
                />
              </label>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import GoodsItem from "./goods/goods-item.vue";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import { mapGetters } from "vuex";
// import {validatePayments} from './controls/payments'
// import { isGoodsRangeValid, isCorrectStartEnd, rangeToArray } from "@/helpers/inputs";
// import GoodsModal from './goods/goods-modal.vue'

// import DownloadExcel from './goods/download-excel.vue'
// import blockVisibility from '@/mixins/block-visibility'

export default {
  components: {
    // GoodsItem,
    // GoodsModal,
    // DownloadExcel
  },
  mixins: [blockAutoUpdate],
  data:() => ({
    block_id: "passenger-about-money",
    inProcess:false,
    waresModal: false,
    waresParams: null,
    dialogAccept: false,
    nsiTransportMarks: [],
    cash: [],
    about_money: {
      declaration_id: null,
      money_exists: "",
    }
  }),
  computed: {
    ...mapGetters({
      selected: 'ptd/getSelected',
      wares: "ptd/getSelectedWares",
    }),
    totalCost(){
      if(!this.cash.length) return ''
      const equal_currency = this.cash.every( i => i.currency_letter === this.cash[0].currency_letter )
      if(equal_currency){
        let total = 0
        total = this.cash.reduce((a, b) => { return a + b.sum }, 0)
        return `${total} ${this.cash[0].currency_letter}`
      }else{
        return ''
      }
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields()
      },
      deep: true,
    }
  },
  created() {
    this.loadTransportMarks()
  },
  methods: {
    changeVisibility(val){
      this.readyToUpdate()
      this.$emit('changeVisibility', val)
    },
    setFields(){
      this.cash = [...this.selected.money.money_json, ...this.selected.travel_check.travel_checks_json, ...this.selected.money_instrument.money_instruments_json]
      const about_money = this.selected.about_money;

      Object.keys(this.about_money).forEach((key) => {
        if (key in about_money) {
          this.about_money[key] = about_money[key];
        }
      })
    },
    readyToUpdate(){
      this.hasChanges = true
      this.fields_been_visited = true
    },
    loadTransportMarks(){
      this.$store.dispatch('catalogs/fetchNsiTransportMarks').then((items) => {
        this.nsiTransportMarks = items
      }).catch(() => {
        this.$snackbar({text: "Ошибка загрузки справочника",color:"red", top:false, right: false});
      })
    },
    addGoodsItem() {
      this.inProcess = true
      this.$store.dispatch("ptd/addNewGoodsItem").then(() => {
        const tabIndexButtons = document.querySelectorAll('.ware-item-toggle')
        tabIndexButtons[tabIndexButtons.length - 1].focus()
        this.inProcess = false
      }).catch(() => {
        this.$snackbar({text:'Не удалось создать товар', color:'red', top:false, right: false})
        this.inProcess = false
      })
    },
    uploadData() {
      const about_money = this.convertEmptyStringsToNull({
        ...this.about_money
      })

      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "about_money",
        value: about_money,
      });
    }
  }
}
</script>

<style scoped>
.dashed-good{
    margin: 20px -20px;
    border: 1px dashed #000;
    border-radius: 8px;
    padding: 20px;
}
</style>
