<template>
  <section
    class="pb-16 mx-auto ptd-main"
    style="width: 1440px"
  >
    <pinned-template
      :tabs="tabs"
    />
    <passenger-toolbox />
    <passenger-main-info />
    <passenger-filler />
    <passenger-cargo-route />
    <passenger-guarantor />
    <div class="dashed-good">
      <passenger-transport-info @changeVisibility="goods_purpose = $event" />
      <passenger-goods v-show="goods_purpose" />
      <documents v-show="goods_purpose" />
      <passenger-teenager v-show="goods_purpose" />
    </div>
    <div class="dashed-good">
      <transport-info @changeVisibility="transport_purpose = $event" />
      <passenger-transport v-show="transport_purpose" />
      <transport-part v-show="transport_purpose" />
    </div>
    <div class="dashed-good">
      <passenger-money @changeVisibility="money_exists = $event" />
      <money-cash v-show="money_exists" />
      <checks v-show="money_exists" />
      <instrument v-show="money_exists" />
      <owner v-show="money_exists" />
      <money-source v-show="money_exists" />
      <purpose v-show="money_exists" />
      <v-container
        v-show="money_exists"
        fluid
      >
        <transfer />
      </v-container>
      <catalogs />
    </div>
    <distinct-division-notification />
    <forbidden-carrier-notification />
  </section>
</template>

<script>
import { mapGetters } from "vuex"
import PinnedTemplate from '../../components/shared/pinned-template.vue'
import PassengerToolbox from '@/components/passenger/passenger-toolbox.vue'
import PassengerMainInfo from '@/components/passenger/passenger-main-info.vue'
import PassengerFiller from '@/components/passenger/passenger-filler.vue'
import PassengerGuarantor from '@/components/passenger/passenger-guarantor.vue'
import PassengerTransportInfo from '@/components/passenger/goods/passenger-transport-info.vue'
import PassengerMoney from '@/components/passenger/passenger-money.vue'
import PassengerCargoRoute from '@/components/passenger/passenger-cargo-route.vue'
import PassengerGoods from '@/components/passenger/passenger-goods.vue'
import PassengerTransport from '@/components/passenger/passenger-transport.vue'
import Catalogs from '@/components/catalogs/modals/resolver.vue'
import PassengerTeenager from '@/components/passenger/passenger-teenager.vue'
import TransportInfo from '@/components/passenger/transport/transport-info.vue'
import Documents from '@/components/passenger/documents/documents.vue'
import TransportPart from '@/components/passenger/transport/transport-part.vue'
import MoneyCash from '@/components/passenger/money/money-cash.vue'
import Checks from '@/components/passenger/money/checks.vue'
import Instrument from '@/components/passenger/money/instrument.vue'
import Owner from '@/components/passenger/money/owner.vue'
import MoneySource from '@/components/passenger/money/money-source.vue'
import Purpose from '@/components/passenger/money/purpose.vue'
import Transfer from '@/components/passenger/money/transfer.vue'
import DistinctDivisionNotification from "@/components/documents/warnings/distinct-division-notification.vue";
import {setDeclarationTitle} from "@/helpers/text";
import ForbiddenCarrierNotification from "@/components/documents/warnings/forbidden-carrier-notification.vue";

export default{
  components: {
    ForbiddenCarrierNotification,
    DistinctDivisionNotification,
    PinnedTemplate,
    PassengerToolbox,
    PassengerMainInfo,
    PassengerFiller,
    PassengerCargoRoute,
    PassengerGuarantor,
    PassengerTransportInfo,
    PassengerMoney,
    PassengerGoods,
    PassengerTransport,
    Catalogs,
    PassengerTeenager,
    Documents,
    TransportInfo,
    TransportPart,
    MoneyCash,
    Checks,
    Instrument,
    Owner,
    MoneySource,
    Purpose,
    Transfer
  },
  provide:{
    rootNamespace:'ptd'
  },
  //   mixins:[offsetMixin],
  data:() => ({
    goods_purpose: null,
    transport_purpose: null,
    money_exists: null,
    tabs:[
      {
        name:'controls',
        component:() => import('@/components/transit/controls/common-errors.vue')
      },
      {
        name:'documents',
        component:() => import('@/components/transit/documents/documents.vue')
      },
      {
        name:'goods-character',
        component:() => import('@/components/transit/goods-character.vue')
      },
      {
        name:'payment-errors',
        component: () => import('@/components/transit/controls/payment-errors.vue'),
      }
    ]
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected"
    })
  },
  watch: {
    selected: {
      handler(ptdDeclaration) {
        const {remark, out_number: outNumber} = ptdDeclaration?.about_declaration ?? {}
        setDeclarationTitle({
          outNumber,
          remark,
          prefix:"ПТД"
        })
        this.setDefaults()
      },
      deep: true
    }
  },
  created(){
    this.$store.dispatch("catalogs/fetchAll")
    const id = this.$route.params.id
    this.$store.dispatch("ptd/getDocumentById", id).catch(err => {
      if(err.response.status === 403){
        this.$router.push('/403')
      }
    });
  },
  methods:{
    setDefaults(){
      this.goods_purpose = this.selected.about_wares.wares_purpose_code
      this.transport_purpose = this.selected.about_transport.transports_purpose_code
      this.money_exists = this.selected.about_money.money_exists
    }
  },
}
</script>


<style scoped>
.dashed-good{
    margin: 20px -20px;
    background-color: rgb(250, 250, 250);
    border-radius: 5px;
    padding: 20px;
}
</style>
