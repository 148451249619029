<template>
  <v-row>
    <v-col
      cols="12"
      class="pb-0"
    >
      <span
        class="font-weight-bold text-body-2"
      >Характеристики товара</span>
    </v-col>

    <v-col cols="2">
      <label>
        Вес нетто
        <div class="d-flex">
          <v-text-field
            v-model="item.net_weight"
            suffix="кг"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            @input="(v) => (item.net_weight = v.replace(',', '.'))"
            @change="(value) => replaceFloatNumbers({value, prop:'net_weight'})"
          />
        </div>
      </label>
    </v-col>
    <v-col cols="3">
      <label>
        Количество товара
        <div class="d-flex">
          <v-text-field
            ref="measure_unit_quantity"
            v-model="item.measure_unit_quantity"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            class="mr-1"
            @input="(v) => (item.measure_unit_quantity = v.replace(',', '.'))"
            @change="(value) => replaceFloat({ value, prop: 'measure_unit_quantity', afterPoint: 2 })"
          />
          <v-autocomplete
            v-model="item.measure_unit_digit"
            class="ml-1"
            :items="measure_units_filtered"
            auto-select-first
            item-text="text"
            item-value="code"
            outlined
            hide-details="auto"
            dense
            background-color="white"
          />
        </div>
      </label>
    </v-col>
    <v-col cols="2">
      <label>
        Стоимость товара
        <div class="d-flex">
          <v-text-field
            ref="invoice_cost"
            v-model="item.invoice_cost"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            @input="(v) => (item.invoice_cost = v.replace(',', '.'))"
            @change="(value) => replaceFloat({ value, prop: 'invoice_cost', afterPoint: 2 })"
          />
        </div>
      </label>
    </v-col>
    <v-col cols="2">
      <label>
        Код валюты
        <div class="d-flex">
          <v-autocomplete
            v-model="item.invoice_currency_letter"
            auto-select-first
            :items="currencies"
            item-text="text"
            item-value="letterCode"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            :filter="filterBySearchField"
          />
          <!-- <v-autocomplete
            v-model="item.invoice_currency_letter"
            auto-select-first
            :filter="filterBySearchField"
            outlined
            hide-details="auto"
            dense
            background-color="white"
          /> -->
        </div>
      </label>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import cloneDeep from 'lodash.clonedeep'
// import { getMeasureUnitShortName, getTransportCodeByName } from '@/helpers/catalogs'
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
// import { convertEmptyStringsToNull } from '@/helpers/objects'
import formatDate from '@/mixins/format-date.js'
import customFieldChecker from '@/mixins/custom-field-checker'
import { normalizeFloat } from "@/helpers/math"
// import {highlightField} from '@/helpers/control'

export default {
  mixins: [filterBySearchFieldMixin, formatDate, customFieldChecker],
  props: {
    index: {
      required: true,
      type: Number,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
    // wareDetails: {
    //   required: true,
    //   type: Array,
    // },
    // showQuantity:{
    //   required: true,
    //   type: Boolean,
    // },
    info:{
      type:Object,
      required:true
    },
    // control:{
    //   required:true,
    //   type:Object,
    // },
    // nsiTransportMarks: {
    //   required:true,
    //   type:Array,
    // }
  },
  data: () => ({
    item: {
      id: "",
      declaration_id: "",
      net_weight: "",
      measure_unit_digit: "",
      measure_unit_letter: "",
      measure_unit_quantity: "",
      invoice_currency_letter: "",
      invoice_cost: ""
    },
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      selected_wares: "ptd/getSelectedWares",
      measure_units: "catalogs/getNsiMeasureUnits",
      currencies: "catalogs/getNsiCurrencies"
    //   transport: "catalogs/getNsiTransportOtherCategories"
    }),
    measure_units_filtered(){
      const available = [167689, 167682]
      return this.measure_units.filter((i) => available.includes(i.id))
    }
  },
  watch: {
    selected_wares: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
    item: {
      handler() {
        // Пропс visited обвновляется позже чем вызывается обработчик,
        // поэтому нужно свойство внутри компонента
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
    // control:{
    //   handler({path}){
    //     if(path !== ""){
    //       highlightField.call(this, {path})
    //     }
    //   },
    //   deep:true,
    //   immediate:true
    // },
  },
  methods: {
    // replaceToInt({value, prop}){
    //   if(value.includes('.')){ //Если не целое число
    //     const int = Number.parseInt(value)
    //     return this.item[prop] = int
    //   }
    //   return this.item[prop] = value
    // },
    replaceFloatNumbers({value, prop}){
      if(value.includes('.')){ //Если не целое число
        let result = ""
        const float = Number.parseFloat(value)
        if(value >= 0.001){
          result = float.toFixed(3).replace(/0*$/,"").replace(/\.*$/,"") // Убираем лишние нули в конце и если нужно точку
        }else{
          result = float.toFixed(6).replace(/0*$/,"").replace(/\.*$/,"")
        }
        return this.item[prop] = result
      }
      return this.item[prop] = value
    },
    replaceFloat({ value, prop, afterPoint = null }) {
      this.item[prop] = normalizeFloat({ value, afterPoint });
    },
    setFields() {
      const ware = cloneDeep(this.selected_wares[this.index])
      Object.keys(this.item).forEach(key => {
        if(key in ware){
          this.item[key] = ware[key]
        }
      })
    },
    updateEditableGoodsItem() {
      this.$emit('update:info', this.item)
      if (this.tn_ved_selected === true) this.tn_ved_selected = false;
    },
    // updateEditableGoodsItem() {
    //   const payload = {
    //     ...this.item,
    //     volume_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.volume_measure_unit_digit),
    //     weight_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.weight_measure_unit_digit),
    //     power_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.power_measure_unit_digit),
    //     vehicle_type_code: getTransportCodeByName(this.transport, this.item.vehicle_type_name)
    //   }
    //   this.$emit('update:info', payload)
    //   if (this.tn_ved_selected === true) this.tn_ved_selected = false;
    // },
  },
}
</script>

<style scoped>
th{
  font-size: 10px !important;
}
td{
  font-size: 12px !important;
}
.v-divider{
  border-style: dashed;
}
</style>
