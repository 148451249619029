<template>
  <v-container
    fluid
  >
    <!-- <v-dialog
        v-model="dialogAccept"
        width="550"
      >
        <v-card>
          <v-toolbar
            color="#333"
            dark
          >
            <div class="text-h5">
              Подтвердить действие
            </div>
          </v-toolbar>
          <div class="pa-6">
            <div class="text-body-1 black--text">
              Удалить все товары?
            </div>
          </div>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="#333"
              text
              @click="dialogAccept = false"
            >
              Отмена
            </v-btn>
            <v-btn
              color="#5cb7b1"
              dark
            >
              Подтвердить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->

    <v-row class="mb-1">
      <v-col
        cols="2"
        class="d-flex align-center"
      >
        <span class="font-weight-bold">Всего товаров ({{ wares.length }})</span>
      </v-col>
    </v-row>
    <div class="rs-goods-list">
      <goods-item
        v-for="(item, idx) in wares"
        :key="'goods-items-' + idx"
        ref="goodsItem"
        :item="item"
        :index="idx"
      />
      <!--
          :nsi-transport-marks="nsiTransportMarks"
          :blockedvis="blocked_visibility" -->
    </div>

    <v-col
      cols="12"
      class="px-0 d-flex justify-space-between align-end"
    >
      <v-btn
        color="#5CB7B1"
        :disabled="inProcess"
        @click="addGoodsItem"
      >
        <v-icon>mdi-plus</v-icon> Добавить товар
      </v-btn>
    </v-col>
  </v-container>
</template>

<script>
import GoodsItem from "./goods/goods-item.vue"
import { mapGetters } from "vuex"
import { eventBus } from "@/bus.js"
// import {validatePayments} from './controls/payments'
// import { isGoodsRangeValid, isCorrectStartEnd, rangeToArray } from "@/helpers/inputs";
// import GoodsModal from './goods/goods-modal.vue'

// import DownloadExcel from './goods/download-excel.vue'
// import blockVisibility from '@/mixins/block-visibility'

export default {
  components: {
    GoodsItem,
    // GoodsModal,
    // DownloadExcel
  },
  //   mixins: [blockVisibility],
  data:() => ({
    inProcess:false,
    waresModal: false,
    waresParams: null,
    dialogAccept: false,
    nsiTransportMarks: []
  }),
  computed: {
    ...mapGetters({
      selected: 'ptd/getSelected',
      wares: "ptd/getSelectedWares",
    }),
  },
  created() {
    this.loadTransportMarks()
  },
  // beforeDestroy(){
  //   openGoodsModal.unsubscribe()
  // },
  methods: {
    // validatePayments,
    loadTransportMarks(){
      this.$store.dispatch('catalogs/fetchNsiTransportMarks').then((items) => {
        this.nsiTransportMarks = items
      }).catch(() => {
        this.$snackbar({text: "Ошибка загрузки справочника",color:"red", top:false, right: false});
      })
    },
    // deleteAllGoods(){
    //   this.$store.dispatch("statistics/deleteAllGoods")
    //     .then(() => {
    //       this.$snackbar({text:"Действие применено", top:false, right: false})
    //     })
    //     .catch(() => {
    //       this.$snackbar({text:'Ошибка', color:'red', top:false, right: false})
    //     })
    //   this.dialogAccept = false
    // },
    // deleteSeveralGoods(){
    //   this.waresParams = {action: 'delete'}
    //   this.waresModal = true
    // },
    // onOpenModal(params){
    //   this.waresParams = params
    //   this.waresModal = true
    // },
    addGoodsItem() {
      this.inProcess = true
      this.$store.dispatch("ptd/addNewGoodsItem").then(() => {
        // const tabIndexButtons = document.querySelectorAll('.ware-item-toggle')
        // tabIndexButtons[tabIndexButtons.length - 1].focus()
        this.inProcess = false
      }).catch(() => {
        this.$snackbar({text:'Не удалось создать товар', color:'red', top:false, right: false})
        this.inProcess = false
      }).finally(() => {
        const idx = this.wares.length - 1
        eventBus.$emit("openLastGood", idx)
      })
    },
  },
};
</script>
