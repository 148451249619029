<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div
          :id="block_id"
          v-click-outside="triggerOnFocus"
          class="statistics-box pa-4"
        >
          <v-row>
            <v-col
              cols="3"
              class="pb-0"
            >
              <div class="font-weight-bold d-flex align-center mt-2">
                Владелец денежных средств
              </div>
            </v-col>
            <v-col cols="9">
              <div class="font-weight-regular d-flex align-center ml-12">
                Декларант
                <v-switch
                  tabindex="-1"
                  color="#5CB7B1"
                  class="mx-1"
                  dense
                  hide-details="auto"
                />
                Иное лицо
              </div>
            </v-col>
            <v-col cols="10">
              <label>
                Фамилия, имя, отчество (наименование юридического лица)
                <v-text-field
                  v-model="money_owner_json.name"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="2">
              <label>
                Страна
                <v-autocomplete
                  v-model="money_owner_json.country_letter"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="text"
                  item-value="letterCode"
                  :filter="filterBySearchField"
                />
              </label>
            </v-col>
            <v-col cols="1">
              <label>
                Индекс
                <v-text-field
                  v-model="money_owner_json.postal_index"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="3">
              <label>
                Область/Район
                <v-text-field
                  v-model="money_owner_json.region"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="2">
              <label>
                Город/Деревня
                <v-text-field
                  v-model="money_owner_json.city"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="3">
              <label>
                Улица
                <v-text-field
                  v-model="money_owner_json.street_house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="1">
              <label>
                Дом
                <v-text-field
                  v-model="money_owner_json.house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="2">
              <label>
                Номер помещения
                <v-text-field
                  v-model="money_owner_json.room"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="2">
              <label>
                Сумма
                <v-text-field
                  v-model="money_owner_json.sum"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="1">
              <label>
                Валюта
                <v-autocomplete
                  v-model="money_owner_json.currency_letter"
                  auto-select-first
                  :items="currencies"
                  item-text="letterCode"
                  item-value="letterCode"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :filter="filterBySearchField"
                />
              </label>
            </v-col>
            <v-col cols="2">
              <label>
                Наим. денежного инструм.
                <v-text-field
                  v-model="money_owner_json.money_instrument_name"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="2">
              <label>
                Кол-во денежных инструм.
                <v-text-field
                  v-model="money_owner_json.money_instrument_quantity"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"

export default {
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: "passenger-money-owner",
    money_owner: {
      declaration_id: null,
    },
    money_owner_json: {
      city: "",
      country_letter: "",
      currency_letter: "",
      house: "",
      money_instrument_name: "",
      money_instrument_quantity: "",
      name: "",
      postal_index: "",
      region: "",
      room: "",
      street_house: "",
      sum: ""
    }
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      user: "auth/getUser",
      countries: "catalogs/getNsiCountries",
      currencies: "catalogs/getNsiCurrencies"
    })
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    }
  },
  methods: {
    readyToUpdate(){
      this.hasChanges = true
      this.fields_been_visited = true
    },
    setFields() {
      const money_owner = this.selected.money_owner
      const money_owner_json = this.selected.money_owner.money_owner_json

      Object.keys(this.money_owner).forEach((key) => {
        if (key in money_owner) {
          this.money_owner[key] = money_owner[key]
        }
      })

      Object.keys(this.money_owner_json).forEach((key) => {
        if (key in money_owner_json) {
          this.money_owner_json[key] = money_owner_json[key]
        }
      })
    },
    uploadData() {
      const money_owner = this.convertEmptyStringsToNull({
        ...this.money_owner,
        money_owner_json: {
          ...this.money_owner_json
        }
      })

      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "money_owner",
        value: money_owner
      })
    }
  }
}
</script>
