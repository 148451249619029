<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row
          class="my-auto mx-0 statistics-box"
        >
          <v-col
            :id="block_id"
            v-click-outside="triggerOnFocus"
            cols="12"
            lg="6"
          >
            <div class="mb-4">
              <v-tooltip
                v-for="(btn, idx) in visibleActions"
                :key="idx"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="mr-2"
                    min-width="36px"
                    max-width="36px"
                    v-bind="attrs"
                    :disabled="btn.loading"
                    :loading="btn.loading"
                    v-on="on"
                    @click="callFunction(btn.action, { action: btn.action })"
                  >
                    <v-icon :color="btn.color || ''">
                      {{ btn.icon }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ btn.name }}</span>
              </v-tooltip>
            </div>
            <v-row>
              <v-col
                cols="12"
                lg="4"
              >
                <v-text-field
                  ref="out_number"
                  v-model="about_declaration.out_number"
                  outlined
                  dense
                  :error="about_declaration.out_number?.length > 50"
                  maxlength="50"
                  hide-details="auto"
                  background-color="white"
                />
              </v-col>
              <v-col
                cols="12"
                lg="3"
                class="pb-0"
              >
                <custom-date-picker
                  ref="declaration_date"
                  v-model="about_declaration.declaration_date"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                />
              </v-col>
            </v-row>
          </v-col>
          <document-control
            v-if="!pinned.includes('controls')"
            class="ml-2"
            source="ptd/getControl"
            pin-name="controls"
          >
            <template slot="action">
              <common-errors-action />
            </template>
          </document-control>
        </v-row>
      </v-col>
    </v-row>
    <component
      :is="activeComponent"
      :ref="getReferenceAsync"
      :show.sync="showComponent"
      :params="activeComponentParams"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CustomDatePicker from "@/components/shared/custom-date-picker";
import DocumentControl from "@/components/shared/document-control.vue";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import ToolboxMixin from "@/mixins/toolbox.mixin";
import {
  downloadXml,
  downloadFile,
  getFilenameFromContentDisposition,
  validateRequiredParams,
  sendToNces,
  performDocumentControl,
  getXmlString,
  getSignedDocumentPTD,
  SELECTED_REQUIRED, NAMESPACE_REQUIRED, USER_REQUIRED, PTO_REQUIRED,
} from "@/helpers/documents";
import CommonErrorsAction from "@/components/passenger/controls/common-errors-action.vue"

export default {
  components: {
    CustomDatePicker,
    DocumentControl,
    CommonErrorsAction
  },
  mixins: [blockAutoUpdate, ToolboxMixin],
  data: () => ({
    block_id: "passenger-about-declaration",
    namespace:"ptd",
    selectedPto:null,
    actions: [
      {
        visible: true,
        loading: false,
        icon: "mdi-upload",
        action: "sendDocument",
        name: "Отправить в ИСТО",
      },
      {
        visible: true,
        icon: "mdi-file-pdf-box",
        action: "downloadPdf",
        name: "Скачать PDF",
      },
      {
        visible: true,
        loading: false,
        icon: "mdi-file-xml-box",
        action: "downloadXml",
        name: "Скачать XML",
      },
      {
        visible: false,
        loading: false,
        icon: "",
        action: "showXsdControlResponse",
        name: "Контроль XSD",
        component: () =>
          import("@/components/documents/xsd-control-response.vue"),
      },
      {
        visible: false,
        loading: false,
        icon: "",
        action: "showDocumentControlResponse",
        name: "Контроль FLK",
        component: () =>
          import("@/components/shared/document-control-response.vue"),
      },
      {
        visible: false,
        icon: "",
        action: "showDocumentControlPto",
        name: "Контроль ПТО",
        component: () => import("@/components/shared/document-control-pto.vue"),
      },
    ],
    about_declaration: {
      declaration_date: null,
      declaration_id: null,
      out_number: ""
    },
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      pinned: "ui/getPinned",
      user: "auth/getUser",
      requests: "ptd/getRequests",
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  methods: {
    validateRequiredParams,
    performDocumentControl,
    getXmlString,
    getSignedDocumentPTD,
    sendToNces,
    sendDocument(){
      this.setLoading('sendDocument', true)
      this.validateRequiredParams({
        ...SELECTED_REQUIRED,
        ...NAMESPACE_REQUIRED,
        ...USER_REQUIRED,
        ...PTO_REQUIRED
      })
        .then(this.performDocumentControl)
        .then(this.displayControlResponse)
        .then(this.showDocumentControlPto)
        .then(this.updateSelectedPto)
        .then(this.getXmlString)
        .then(this.getSignedDocumentPTD)
        .then(this.sendToNces)
        .catch(() => {})
        .finally(() => this.setLoading('sendDocument', false))
    },
    setFields() {
      const { about_declaration } = this.selected || {};
      for (let key in about_declaration) {
        if (key in this.about_declaration) {
          this.about_declaration[key] = about_declaration[key];
        }
      }
    },
    downloadXml() {
      this.$store.dispatch("ptd/getXml", this.selected.id).then((res) => {
        downloadXml(res.data, getFilenameFromContentDisposition(res));
      });
    },
    uploadData() {
      const about = this.convertEmptyStringsToNull(this.about_declaration);
      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "about_declaration",
        value: about,
      });
    },
    downloadPdf() {
      this.$store
        .dispatch("ptd/downloadPdf", this.selected.id)
        .then((res) => {
          downloadFile({
            data: res.data,
            type: "application/pdf",
            name: getFilenameFromContentDisposition(res),
          });
        })
        .catch(() => this.$error("Ошибка"))
    },
  },
};
</script>

