<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12">
        <div
          class="statistics-box pa-4"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="font-weight-bold d-flex align-center mt-2">
                Часть транспортного средства
                <v-menu offset-x>
                  <template #activator="{ on }">
                    <v-icon
                      tabindex="-1"
                      dense
                      class="mx-1"
                      v-on="on"
                    >
                      mdi-tune
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      :disabled="!selectedDocs.length"
                      @click="deleteSelected"
                    >
                      Удалить выбранные документы
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
            <v-col
              v-if="!transport_parts_json.length"
              cols="12"
              class="text-center"
            >
              <v-btn
                class="document-add elevation-0 px-15"
                color="transparent"
                @click="addPart"
              >
                Добавить <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
            >
              <div
                v-if="transport_parts_json.length"
                class="d-flex align-center"
                style="gap:20px;"
              >
                <div class="t-check">
                  <v-checkbox
                    tabindex="-1"
                    dense
                    hide-details="true"
                    color="#5CB7B1"
                    :value="isAllSelected"
                    @change="checkAll"
                  />
                  <!--
                    @change="checkAll" -->
                </div>
                <div
                  class="t-index"
                  style="width:60px;"
                >
                  № п/п
                </div>
                <div
                  class="t-number"
                  style="width:220px;"
                >
                  Идентификационный номер
                </div>
                <div
                  class="t-descr"
                  style="width: 68%;"
                >
                  Описание (наименование) заменной части
                </div>
              </div>
              <transport-part-item
                v-for="(item, idx) in transport_parts_json"
                :key="idx"
                :item="item"
                :transport-parts-length="transport_parts_json.length"
                :index="idx"
                :transport-part="transport_part"
                :all-parts="transport_parts_json"
                :selected-docs="selectedDocs"
                @addPart="addPart"
                @deletePart="deletePart"
                @select="onSelect"
              />
              <!-- @addPart="addPart"
                @deletePart="deletePart"
                @updatePart="updatePart" -->
              <!-- <label>
                Цель перемещения
                <v-autocomplete
                  v-model="about_transport.transports_purpose_code"
                  :items="codes"
                  auto-select-first
                  item-text="txt"
                  item-value="val"
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  class="mr-1 p-0"
                />
              </label> -->
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
// import { filterBySearchFieldMixin } from "@/mixins/catalogs"
// import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import TransportPartItem from '@/components/passenger/transport/transport-part-item.vue'

export default {
  components : {
    TransportPartItem
  },
  // mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: "passenger-transport-part",
    transport_part: {
      declaration_id: null
    },
    transport_parts_json: [],
    selectedDocs: [],
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      user: "auth/getUser"
    }),
    isAllSelected(){
      return this.selectedDocs.length && this.selectedDocs.length === this.transport_parts_json.length
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    }
  },
  methods: {
    // readyToUpdate(){
    //   this.hasChanges = true
    //   this.fields_been_visited = true
    // },
    unsetSelected(){
      this.selectedDocs = []
    },
    deleteSelected(){
      this.transport_parts_json = this.transport_parts_json.filter((i,idx) => !this.selectedDocs.includes(idx))
      this.uploadData()
      this.unsetSelected()
    },
    checkAll(){
      if(this.selectedDocs.length === this.transport_parts_json.length){
        this.selectedDocs = []
      }else{
        this.selectedDocs = this.transport_parts_json.map((i, idx) => idx)
      }
    },
    onSelect(idx){
      if(this.selectedDocs.includes(idx)){
        this.selectedDocs = this.selectedDocs.filter(i => i !== idx)
      }else{
        this.selectedDocs.push(idx)
      }
    },
    addPart(){
      this.transport_parts_json.push({
        number: null,
        description: null
      })
      this.uploadData()
    },
    deletePart(i){
      this.transport_parts_json.splice(i, 1)
      this.uploadData()
    },
    updatePart(idx, part){
      this.transport_parts_json[idx] = part
      // this.readyToUpdate()
      this.uploadData()
    },
    setFields() {
      const transport_part = this.selected.transport_part
      Object.keys(this.transport_part).forEach((key) => {
        if (key in transport_part) {
          this.transport_part[key] = transport_part[key];
        }
      })
      this.transport_parts_json = this.selected.transport_part.transport_parts_json.length ? [...this.selected.transport_part.transport_parts_json] : []
    },
    uploadData() {
      const transport_part = {
        ...this.transport_part,
        transport_parts_json: [
          ...this.transport_parts_json,
        ],
      }
      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "transport_part",
        value: transport_part,
      })
        .then(() => {
          this.$snackbar({text:"Обновлено [Часть транспортного средства]",color:"green", top:false, right: false});
        })
    },
  }
};
</script>
