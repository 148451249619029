<template>
  <!-- <div>{{ customsPoints[0] }} {{ amount }}</div> -->
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="d-flex align-center mt-2"
    style="gap:20px;"
  >
    <div class="t-check">
      <v-checkbox
        tabindex="-1"
        dense
        hide-details="true"
        color="#5CB7B1"
        :value="selectedDocs.includes(index)"
        @change="updateSelected"
      />
    </div>
    <div
      class="t-index"
      style="width:60px;"
    >
      <v-text-field
        :value="index + 1"
        outlined
        disabled
        dense
        hide-details="auto"
        background-color="#D3D3D3"
      />
    </div>
    <div
      class="t-number"
      style="width:120px;"
    >
      <v-autocomplete
        v-model="route_item.country_letter"
        auto-select-first
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :items="countries"
        item-text="text"
        item-value="letterCode"
        :filter="filterBySearchField"
      />
    </div>
    <div
      class="t-descr"
      style="width: 220px;"
    >
      <v-text-field
        v-model="route_item.destination_place"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div
      class="t-descr"
      style="width: 280px;"
    >
      <v-autocomplete
        v-model="route_item.destination_customs_code"
        auto-select-first
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :items="customsPoints"
        item-text="search"
        item-value="code"
        :filter="filterBySearchField"
      />
      <!-- <v-text-field
        v-model="route_item.destination_customs_code"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      /> -->
    </div>
    <div
      class="t-descr"
      style="width: 160px;"
    >
      <custom-date-picker
        v-model="route_item.date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </div>

    <div
      class="t-add d-flex"
      style="width: 72px;"
    >
      <v-btn
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="deletePart"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === amount - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="addPart"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import CustomDatePicker from "@/components/shared/custom-date-picker.vue"
// import { mapGetters } from "vuex"

export default {
  components: { CustomDatePicker },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin],
  props: {
    item: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    },
    amount: {
      required: true,
      type: Number
    },
    base: {
      required: true,
      type: Object
    },
    shell: {
      required: true,
      type: Object
    },
    all: {
      required: true,
      type: Array
    },
    // справочники
    customsPoints: {
      required: true,
      type: Array
    },
    countries: {
      required: true,
      type: Array
    },
    selectedDocs: {
      required: true,
      type: Array
    }
  },
  data(){
    return {
      route_item: {
        country_letter: "",
        destination_place: "",
        destination_customs_code: "",
        date: ""
      }
    }
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `transfer-route-${this.index + 1}`
        for (let key in newVal) {
          if (key in this.route_item) {
            this.route_item[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    updateSelected(){
      this.$emit('select', this.index)
    },
    addPart(){
      this.$emit('addPart')
    },
    deletePart(){
      this.$emit('deletePart', this.index)
    },
    uploadData() {
      const transfer_routes = [...this.all]
      transfer_routes[this.index] = {
        ...this.route_item
      }

      const money_transfer = this.convertEmptyStringsToNull({
        ...this.base,
        money_transfer_json: {
          ...this.shell,
          money_transfer_routes: [...transfer_routes]
        }
      })

      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "money_transfer",
        value: money_transfer,
      })
    }
  }
}
</script>
