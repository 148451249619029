<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="d-flex align-center mt-2"
    style="gap:10px;"
  >
    <div class="t-check">
      <v-checkbox
        tabindex="-1"
        dense
        hide-details="true"
        color="#5CB7B1"
        :value="selectedDocs.includes(index)"
        @change="updateSelected"
      />
    </div>
    <div
      class="t-index"
      style="width:60px;"
    >
      <v-text-field
        :value="index + 1"
        outlined
        disabled
        dense
        hide-details="auto"
        background-color="#D3D3D3"
      />
    </div>
    <div
      class="t-number"
      style="width:220px;"
    >
      <v-text-field
        v-model="inst.name"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div
      class="t-number"
      style="width:200px;"
    >
      <v-text-field
        v-model="inst.emitter"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div
      class="t-number"
      style="width:160px;"
    >
      <!-- <v-text-field
        v-model="inst.release_date"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      /> -->
      <custom-date-picker
        v-model="inst.release_date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </div>
    <div
      class="t-number"
      style="width:140px;"
    >
      <v-text-field
        v-model="inst.sum"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div
      class="t-descr"
      style="width: 100px;"
    >
      <v-autocomplete
        v-model="inst.currency_letter"
        auto-select-first
        :items="currencies"
        item-text="letterCode"
        item-value="letterCode"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :filter="filterBySearchField"
      />
    </div>
    <div
      class="t-number"
      style="width:210px;"
    >
      <v-text-field
        v-model="inst.number"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div
      class="t-number"
      style="width:80px;"
    >
      <v-text-field
        v-model="inst.quantity"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <!-- <div
      class="t-number"
      style="width:180px;"
    >
      <v-text-field
        v-model="check.sum"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div
      class="t-descr"
      style="width: 100px;"
    >
      <v-autocomplete
        v-model="check.currency_letter"
        auto-select-first
        :items="currencies"
        item-text="letterCode"
        item-value="letterCode"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :filter="filterBySearchField"
      />
    </div> -->
    <div
      class="t-add d-flex"
      style="width: 72px;"
    >
      <v-btn
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="deletePart"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === instItemsLength - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="addPart"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import CustomDatePicker from "@/components/shared/custom-date-picker.vue"
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import { mapGetters } from "vuex"

export default {
  components: {
    CustomDatePicker
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin],
  props: {
    item: {
      required: true,
      type: Object
    },
    instItemsLength: {
      required: true,
      type: Number
    },
    index: {
      required: true,
      type: Number
    },
    allInstruments: {
      required: true,
      type: Array
    },
    moneyInstrument: {
      required: true,
      type: Object
    },
    selectedDocs: {
      required: true,
      type: Array
    }
  },
  data(){
    return {
      block_id: null,
      inst: {
        name:"",
        emitter: "",
        release_date: "",
        sum: "",
        currency_letter: "",
        number: "",
        quantity: ""
      }
    }
  },
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      user: "auth/getUser",
      currencies: "catalogs/getNsiCurrencies"
    }),
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `money-inst-${this.index + 1}`
        for (let key in newVal) {
          if (key in this.inst) {
            this.inst[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    updateSelected(){
      this.$emit('select', this.index)
    },
    addPart(){
      this.$emit('addPart')
    },
    deletePart(){
      this.$emit('deletePart', this.index)
    },
    uploadData() {
      const insts_json = [...this.allInstruments]
      insts_json[this.index] = {
        ...this.inst
      }

      const money_instrument = this.convertEmptyStringsToNull({
        ...this.moneyInstrument,
        money_instruments_json: [...insts_json]
      })

      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "money_instrument",
        value: money_instrument,
      })
      // .then(() => {
      //   this.$snackbar({text:"Обновлено [Часть транспортного средства]",color:"green", top:false, right: false});
      // })
    },
  }
}
</script>
